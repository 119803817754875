import { INVALID_PASSWORD_CONFIRM } from "@/constants/invalids";
import { validator } from "@/utils/validator";

export const registerSchema = validator.object().shape({
  email: validator.string().trim().emptyToNull().autoTransformToHalfWidth().label("メールアドレス").required(),
  newPassword: validator.string().trim().emptyToNull().autoTransformToHalfWidth().strongPassword().label("新パスワード").required(),
  companyCode: validator.string().trim().emptyToNull().autoTransformToHalfWidth().label("企業ID").required(),
  confirmNewPassword: validator
    .string()
    .trim()
    .autoTransformToHalfWidth()
    .oneOf([validator.ref("newPassword")], INVALID_PASSWORD_CONFIRM)
    .label("新パスワード（確認）")
    .required(),
});
