import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { TAcceptActivityRequest } from "@/features/regular-document/types/acceptActivityRequest";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

export const TargetPeriod = () => {
  const { control, getValues } = useFormContext<TAcceptActivityRequest>();
  return (
    <InnerBox title="対象期間" bordered p={3} required>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormField control={control} name="year">
            <Input readonly clearable={false} commasFormat={false} />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="quarter">
            <Select readonly enumName="EQuarter" />
          </FormField>
        </Grid>
      </Grid>
    </InnerBox>
  );
};
