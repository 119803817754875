import { setPasswordApi } from "@/api/services/main/auth";
import { Button } from "@/components/elements/Button";
import { Checkbox } from "@/components/elements/Checkbox";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Linkable } from "@/components/elements/Linkable";
import { PASSWORD_CHANGE_SUCCESS_MESSAGE } from "@/constants/common";
import { registerSchema } from "@/features/auth/schema/registerSchema";
import { TRegisterForm } from "@/features/auth/types/registerForm";
import { TRegisterQuery } from "@/features/auth/types/registerQuery";
import { AppRoutes } from "@/routes/config";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { createUrlWithParams, getQueryFromURL } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

const initForm: TRegisterForm = {
  email: "",
  newPassword: "",
  confirmNewPassword: "",
  companyCode: "",
};

export const RegisterForm = () => {
  const query = getQueryFromURL<TRegisterQuery>();
  const { pathname } = useLocation();
  const [agree, setAgree] = useState(false);
  const methods = useForm<TRegisterForm>({
    mode: "onChange",
    defaultValues: { ...initForm, email: query?.email ?? "", companyCode: query?.companyCode ?? "" },
    resolver: yupResolver(registerSchema),
  });
  const { control } = methods;

  const onSubmit = async (data: TRegisterForm) => {
    const { newPassword } = data;
    if (!query) return;
    const { email, resetToken, companyCode } = query;
    try {
      if (email && resetToken) {
        await setPasswordApi({ email, newPassword, resetToken, companyCode });
        toast.success(PASSWORD_CHANGE_SUCCESS_MESSAGE);
        const url = createUrlWithParams(AppRoutes.login, { email, companyCode });

        // Delay 2 seconds to show success message
        setTimeout(() => {
          location.href = url;
        }, 2000);
      }
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const isRegisterRoute = pathname === AppRoutes.register;

  return (
    <Form methods={methods} onSubmit={onSubmit} enableBlocker={false} schema={registerSchema}>
      <Stack gap={3} width={400}>
        <FormField control={control} name="companyCode">
          <Input disabled size="lg" />
        </FormField>
        <FormField control={control} name="email">
          <Input disabled size="lg" />
        </FormField>
        <FormField control={control} name="newPassword">
          <Input type="password" size="lg" />
        </FormField>
        <FormField control={control} name="confirmNewPassword">
          <Input type="password" size="lg" />
        </FormField>
        <Checkbox
          label={
            <Stack direction={"row"}>
              <Linkable href="https://iro-hana.com/privacy/" color="#1890FF" target="_blank">
                プライバシーポリシー
              </Linkable>
              に同意します。
            </Stack>
          }
          onChange={(e) => setAgree(e.target.checked)}
        />
        <Button type="submit" variant="primary" disabled={!agree} fullWidth>
          {isRegisterRoute ? "登録" : "パスワード再設定"}
        </Button>
      </Stack>
    </Form>
  );
};
