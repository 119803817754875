import { Button } from "@/components/elements/Button";
import { Modal } from "@/components/elements/Modal";
import { RefreshEmployeeInfoBtn } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/RefreshEmployeeInfoBtn";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { useVisible } from "@/hooks/useVisible";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";

type TRemindPassportModalProps = {
  setIsEditable: (v: boolean) => void;
};

export const RemindPassportModal: FC<TRemindPassportModalProps> = ({ setIsEditable }) => {
  const methods = useFormContext<TEmployeeApplicationForm>();

  const { control } = methods;
  const employeeIdWatchField = useWatch({ control, name: "employeeId" });
  const employeeInfoPassportCode = useWatch({ control, name: "employeeInfoPassportCode" });

  const modal = useVisible(!employeeInfoPassportCode);

  return (
    <Modal isOpen={modal.visible} width={480} enableFooter={false} enableCloseIcon={false}>
      <Typography component="p" pt={1} variant="body14">
        外国人の旅券番号が記入されていませんが、続行して良いですか？
      </Typography>
      <Stack mt={3} alignItems="center">
        <Button variant="primary" size="sm" onClick={() => modal.close()}>
          OK
        </Button>
        <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={2} mt={2}>
          <RefreshEmployeeInfoBtn setIsEditable={setIsEditable} handleCallback={() => modal.close()} />
          <Button
            variant="primary"
            size="sm"
            component={Link}
            target="_blank"
            to={`${getDynamicRoute(AppRoutes.employeeEdit, { id: employeeIdWatchField })}#passport_code_input`}
          >
            特定技能外国人の編集へ
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
