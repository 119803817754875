import { fetchFileInfoApi } from "@/api/services/global/file";
import { createMultiAnytimeFilesApi, finishUploadDocumentStepApi, useAnytimeDocument, useAnytimeFileList } from "@/api/services/main/any-time";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Button } from "@/components/elements/Button";
import { UploadPopup } from "@/components/elements/UploadPopup";
import { FILE_STATUS, FILE_UPLOAD_FOR, MIME_TYPES } from "@/constants/file";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useDialog } from "@/hooks/useDialog";
import { useVisible } from "@/hooks/useVisible";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { TExtendFile } from "@/types/file";
import { findDocumentStep } from "@/utils/document";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

type TAnytimeFilesTopProps = {
  readonly?: boolean;
};

export const AnytimeFilesTop: FC<TAnytimeFilesTopProps> = ({ readonly }) => {
  const { id } = useParams();
  const anytimeUploadFilePopup = useVisible();
  const confirmFinish = useDialog();
  const queryClient = useQueryClient();
  const { data: anytimeResult } = useAnytimeDocument();
  const anytimeData = anytimeResult?.data;
  const stepData = findDocumentStep(anytimeData, DOCUMENT_STEP_KEYS.ANY_TIME_UPLOAD_DOCUMENT);
  const navigate = useNavigate();
  const { data: anytimeFileListResult } = useAnytimeFileList();

  const [isCheckAnytimeFileLoading, setIsCheckAnytimeFileLoading] = useState<boolean>(false);
  const [isDisableUploadBtn, setIsDisableUploadBtn] = useState<boolean>(false);

  const handleCheckMaximumAnytimeFiles = async (newFiles: TExtendFile[]) => {
    //Maximum upload of 19 files, Total file upload capacity of 1 user is 19MB
    if (!anytimeFileListResult) return true;
    setIsCheckAnytimeFileLoading(true);
    const uploadedAnytimeFiles = await Promise.all(
      anytimeFileListResult.data.map(async (item) => {
        const rs = await fetchFileInfoApi(item.filePath);
        return rs.data;
      }),
    );
    //Check Maximum upload of 19 files
    if (uploadedAnytimeFiles.length + newFiles.length > 19) {
      toast.error("19ファイル以下を選択してください");
      setIsCheckAnytimeFileLoading(false);
      return true;
    }
    //Check Total file upload capacity of 1 user is 19MB
    if (uploadedAnytimeFiles.reduce((prev, curr) => curr.size + prev, 0) + newFiles.reduce((prev, curr) => curr.size + prev, 0) > 19 * 1000 * 1000) {
      toast.error("総容量は19MBまでです。別のファイルをアップロードしてください");
      setIsCheckAnytimeFileLoading(false);
      return true;
    }
    setIsCheckAnytimeFileLoading(false);
    setIsDisableUploadBtn(true);
    return false;
  };

  const handleFinishUploadFiles = async (_: TExtendFile[], newFiles: TExtendFile[]) => {
    const filesWithStatusOk = newFiles.filter((file) => file.status === FILE_STATUS.OK);
    if (filesWithStatusOk.length === 0 || !id || !anytimeData) return;
    if (!stepData) {
      navigate(getDynamicRoute(AppRoutes.anytimeDocument, { id }));
      return;
    }
    try {
      await createMultiAnytimeFilesApi(id, {
        files: newFiles.flatMap((file) => (file.filePath ? { filePath: file.filePath, anyTimeDocumentStepId: toNumber(stepData.id) } : [])),
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_FILE_LIST, id],
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_DOCUMENT, id],
      });
    } catch (error) {
      showError(error);
    } finally {
      setIsDisableUploadBtn(false);
    }
  };

  const handleSetFinishDocumentStep = async () => {
    try {
      const agree = await confirmFinish.show();
      if (!agree) return;
      await finishUploadDocumentStepApi(Number(stepData?.id));
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_DOCUMENT, id],
      });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="end" alignItems="center">
        {!readonly && (
          <Stack direction="row" alignItems="end" spacing={2}>
            <Button size="md" variant="primary" disabled={stepData?.status === DOCUMENT_STATUS.COMPLETE} onClick={handleSetFinishDocumentStep}>
              アップロード完了
            </Button>
            <Button size="md" variant="primary" disabled={isDisableUploadBtn} onClick={() => anytimeUploadFilePopup.open()}>
              新規アップロード
            </Button>
          </Stack>
        )}
      </Stack>
      <UploadPopup
        isLoading={isCheckAnytimeFileLoading}
        uploadFor={FILE_UPLOAD_FOR.ANY_TIME_FILES}
        onFinishUpload={handleFinishUploadFiles}
        onDropFiles={handleCheckMaximumAnytimeFiles}
        isOpen={anytimeUploadFilePopup.visible}
        onClose={() => anytimeUploadFilePopup.close()}
        options={{ multiple: true }}
        rules={{ acceptMimeTypes: [...MIME_TYPES.PDF, ...MIME_TYPES.IMAGE, ...MIME_TYPES.EXCEL] }}
        title={<Typography variant="body14Bold">ファイルをアップロードしてください。</Typography>}
      />
      <ConfirmSaveModal open={confirmFinish.open} onOk={confirmFinish.confirm} onClose={confirmFinish.cancel}>
        <Typography>添付書類のアップロードが完了してもよろしいでしょうか。</Typography>
      </ConfirmSaveModal>
    </>
  );
};
