import { createRenewVisaUploadFileDocumentApi, updateRenewVisaUploadFileDocumentApi } from "@/api/services/main/renew-visa";
import { IconCheck } from "@/assets/icons";
import { Checkbox } from "@/components/elements/Checkbox";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { FILE_EXISTED } from "@/constants/file";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { FileBoxInput } from "@/features/renew-visa/components/applicationStep/uploadApplication/FileBoxInput";
import { FileInfo } from "@/features/renew-visa/components/applicationStep/uploadApplication/FileInfo";
import { GroupSixFileBoxInput } from "@/features/renew-visa/components/applicationStep/uploadApplication/GroupSixFileBoxInput";
import { UploadFileBtnActions } from "@/features/renew-visa/components/applicationStep/uploadApplication/UploadFileBtnActions";
import { RENEW_VISA_UPLOAD_APPLICATION_FILE } from "@/features/renew-visa/constants/uploadApplicationFile";
import { useGetRenewVisaUploadFileSchema } from "@/features/renew-visa/hook/useGetRenewVisaUploadFileSchema";
import { useGetUploadFileListWithFilterCondition } from "@/features/renew-visa/hook/useGetUploadFileListWithFilterCondition";
import { useMergeRenewVisaFile } from "@/features/renew-visa/hook/useMergeRenewVisaFile";
import { TRenewVisaUploadFileForm } from "@/features/renew-visa/types/renewVisaUploadFileForm";
import { convertUploadApplicationFormDataToReqDto } from "@/features/renew-visa/utils/convertUploadApplicationFormDataToReqDto";
import { AppRoutes } from "@/routes/config";
import { background, divider, state, text } from "@/theme/colors";
import { TUpdaterDto } from "@/types/common";
import { RENEW_VISA_DOCUMENT_STATUS } from "@/types/enum";
import { setErrorForm, showError } from "@/utils/error";
import { getFormValues } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, styled, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, Fragment } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

type TUploadApplicationFormProps = {
  status?: string;
  previousValues: TRenewVisaUploadFileForm | undefined;
  updater?: TUpdaterDto | undefined;
  readonly?: boolean;
  isEditable?: boolean;
  setIsEditable?: (bool: boolean) => void;
};

export const UploadApplicationForm: FC<TUploadApplicationFormProps> = ({ status, updater, readonly, previousValues, isEditable, setIsEditable }) => {
  const { renewVisaDocumentId, documentStepId } = useParams();

  const { uploadFileListWithFilterCondition, filterConditionArrayKeys, conditions } = useGetUploadFileListWithFilterCondition();

  const { getFilesGroupByTable } = useMergeRenewVisaFile(Number(renewVisaDocumentId));

  const navigate = useNavigate();

  const { renewVisaUploadFileSchema, renewVisaUploadFileDraftSchema } = useGetRenewVisaUploadFileSchema();
  const queryClient = useQueryClient();
  const methods = useForm<TRenewVisaUploadFileForm>({
    defaultValues: previousValues,
    resolver: yupResolver(renewVisaUploadFileSchema),
  });

  const { control } = methods;

  const saveDataForm = async (data: TRenewVisaUploadFileForm, isDraft: boolean) => {
    const { isShowTable1, isShowTable2And1, isShowTable2And2, isShowTable2And3, isShowTable3And1, isShowTable3And2, isShowTable3And3 } =
      await getFilesGroupByTable(data);

    const params = {
      ...data,
      residenceApplicationsForEmployeeTable1FilePath: isShowTable1 ? FILE_EXISTED : null,
      residenceApplicationsForEmployeeTable2And1FilePath: isShowTable2And1 ? FILE_EXISTED : null,
      residenceApplicationsForEmployeeTable2And2FilePath: isShowTable2And2 ? data.residenceApplicationsForEmployeeTable2And2FilePath : null,
      residenceApplicationsForEmployeeTable2And3FilePath: isShowTable2And3 ? data.residenceApplicationsForEmployeeTable2And3FilePath : null,
      residenceApplicationsForEmployeeTable3And1FilePath: isShowTable3And1 ? FILE_EXISTED : null,
      residenceApplicationsForEmployeeTable3And2FilePath: isShowTable3And2 ? FILE_EXISTED : null,
      residenceApplicationsForEmployeeTable3And3FilePath: isShowTable3And3 ? FILE_EXISTED : null,
    };

    if (!renewVisaDocumentId || !documentStepId) return;
    try {
      if (updater) {
        const rs = await updateRenewVisaUploadFileDocumentApi(renewVisaDocumentId, documentStepId, {
          ...convertUploadApplicationFormDataToReqDto(params, filterConditionArrayKeys, isDraft),
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createRenewVisaUploadFileDocumentApi(renewVisaDocumentId, documentStepId, {
          ...convertUploadApplicationFormDataToReqDto(params, filterConditionArrayKeys, isDraft),
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_DETAIL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_UPLOAD_FILE] });

      navigate(getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId }));
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const onSubmit = async (data: TRenewVisaUploadFileForm) => {
    if (readonly) {
      navigate(getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId }));
      return;
    }
    await saveDataForm(data, false);
  };

  const saveDraft = async () => {
    try {
      const data = methods.getValues();
      const values = await getFormValues(data, renewVisaUploadFileDraftSchema);
      await saveDataForm(values as TRenewVisaUploadFileForm, true);
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form onSubmit={onSubmit} methods={methods} schema={renewVisaUploadFileSchema}>
      {uploadFileListWithFilterCondition
        // If select one of A-E and is in progress and is group 6, then do not show this group
        .filter((item) => {
          if (
            conditions.length > 0 &&
            status === RENEW_VISA_DOCUMENT_STATUS.IN_PROGRESS &&
            item.label === RENEW_VISA_UPLOAD_APPLICATION_FILE.GROUP_SIX.label
          ) {
            return false;
          }
          return true;
        })
        // Only show group that has file
        .filter(
          (item) => item.value.length > 0 && item.value.filter((file) => (file.onlyShowWhenHasFile === true && !file.isUploaded ? false : true)),
        )
        .map((item, index) => {
          const isGroupSix = item.label === "提出書類の一覧表";
          return (
            <Stack key={index} sx={{ border: "1px solid #E6E7E8", borderRadius: "8px", marginTop: "16px" }}>
              <CardHeader
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                pl={3}
                gap={10}
                height="56px"
                sx={{ backgroundColor: background.secondary, borderBottom: "1px solid #E6E7E8" }}
              >
                <Typography variant="body14Bold">{item.label}</Typography>
              </CardHeader>
              <Box px={3}>
                <Typography component="h4" variant="body14" mt="16px" mb="24px" whiteSpace="pre-line">
                  {item.subLabel}
                </Typography>
                <Stack gap={2} pb={2}>
                  {item.value
                    .filter((item) => (item.onlyShowWhenHasFile === true && !item.isUploaded ? false : true)) // if onlyShowWhenHasFile true and file not has been uploaded then not show
                    .map((valueItem, index) => (
                      <Fragment key={index}>
                        {!valueItem.children && (
                          <>
                            {isGroupSix ? (
                              <GroupSixFileBoxInput
                                control={control}
                                title={valueItem.uploadScreenLabel}
                                name={valueItem.key}
                                readonly={readonly || !isEditable}
                                filePath={methods.watch(valueItem.key)?.toString()}
                                basicInformationConditions={conditions}
                                required={valueItem.required}
                              />
                            ) : (
                              <Stack
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={10}
                                py={2}
                                px={3}
                                sx={{ border: "1px solid #E6E7E8", borderRadius: "8px" }}
                              >
                                <Box width={680}>
                                  <FileInfo
                                    required={valueItem.required}
                                    titleVariant="sub16Semi"
                                    title={valueItem.uploadScreenLabel}
                                    isUploaded={
                                      (valueItem.multiple
                                        ? ((methods.watch(valueItem.key) as Array<string>)?.length ?? 0) > 0
                                        : !!methods.watch(valueItem.key)) ||
                                      (!!valueItem.isExistKey && !!methods.watch(valueItem.isExistKey))
                                    }
                                    subTitle={valueItem.subUploadScreenLabel}
                                    tooltipContent={valueItem.tooltipContent}
                                  />
                                </Box>
                                <Stack>
                                  <FormField sx={{ width: "192px" }} name={valueItem.key} control={control} errorTextAlign="right">
                                    <FileBoxInput
                                      readonly={readonly || !isEditable || (!!valueItem.isExistKey && !!methods.watch(valueItem.isExistKey))}
                                      multiple={valueItem.multiple}
                                      maxFiles={valueItem.maxFiles}
                                    />
                                  </FormField>
                                  {valueItem.isExistKey && (
                                    <FormField name={valueItem.isExistKey} control={control}>
                                      <Checkbox
                                        checked={methods.getValues(valueItem.isExistKey) || false}
                                        disabled={
                                          readonly ||
                                          !isEditable ||
                                          (valueItem.multiple
                                            ? ((methods.watch(valueItem.key) as Array<string>)?.length ?? 0) > 0
                                            : !!methods.watch(valueItem.key))
                                        }
                                        labelColor={
                                          (
                                            valueItem.multiple
                                              ? ((methods.watch(valueItem.key) as Array<string>)?.length ?? 0) > 0
                                              : !!methods.watch(valueItem.key)
                                          )
                                            ? text.disable
                                            : undefined
                                        }
                                        sx={{ ml: 0.5 }}
                                        label="添付対応済"
                                      />
                                    </FormField>
                                  )}
                                </Stack>
                              </Stack>
                            )}
                          </>
                        )}
                        {valueItem.children && (
                          <Stack sx={{ border: "1px solid #E6E7E8", borderRadius: "8px" }}>
                            <Stack direction="row" alignItems="center" gap={1} px={3} mt={2}>
                              <Stack
                                alignItems="center"
                                justifyContent="center"
                                width={16}
                                height={16}
                                borderRadius={16}
                                bgcolor={
                                  valueItem.children.some((childrenItem) => !!methods.watch(childrenItem.key)) ? state.success_1 : divider.middle
                                }
                              >
                                <IconCheck color={background.white} />
                              </Stack>
                              <Typography component="p" variant="sub16Semi" whiteSpace="pre-line">
                                {valueItem.uploadScreenLabel}
                              </Typography>
                              {valueItem.required && <Typography color={text.error}>*</Typography>}
                            </Stack>
                            <Stack ml={3} gap={1}>
                              {valueItem.children.map((childItem, index) => (
                                <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap={10} py={1} px={3}>
                                  <Box width={680}>
                                    <FileInfo
                                      titleVariant="sub16Semi"
                                      title={childItem.uploadScreenLabel}
                                      showCheckIcon={false}
                                      isUploaded={!!methods.watch(childItem.key)}
                                      tooltipContent={childItem.tooltipContent}
                                    />
                                  </Box>
                                  <FormField sx={{ width: "192px" }} key={index} name={childItem.key} control={control} errorTextAlign="right">
                                    <FileBoxInput readonly={readonly || !isEditable} />
                                  </FormField>
                                </Stack>
                              ))}
                            </Stack>
                          </Stack>
                        )}
                      </Fragment>
                    ))}
                </Stack>
              </Box>
            </Stack>
          );
        })}
      <UploadFileBtnActions saveDraft={saveDraft} readonly={readonly} isEditable={!!isEditable} setIsEditable={(v) => setIsEditable?.(v)} />
    </Form>
  );
};

const CardHeader = styled(Stack)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
