import { TRenewVisaCompanyApplicationDocument } from "@/api/entities/renew-visa-company-application-document.entity";
import { TRenewVisaEmployeeApplicationDocumentFamilyMember } from "@/api/entities/renew-visa-employee-application-document-family-member.entity";
import { TRenewVisaEmployeeApplicationDocumentWorkingHistory } from "@/api/entities/renew-visa-employee-application-document-working-history.entity";
import { TRenewVisaEmployeeApplicationDocument } from "@/api/entities/renew-visa-employee-application-document.entity";
import {
  ERenewVisaConditionsApplyForStaffingTarget,
  ERenewVisaConditionsApplyForSupportPlanDelegation,
} from "@/features/renew-visa/enums/companyApplicationForm";
import {
  EEmployeeCareerCompletionProof,
  EEmployeeCareerJapaneseCertification,
  EEmployeeCareerSkillCertificationOne,
  EMaritalStatus,
  EPaymentMethod,
  EResidenceCardReceiptMethod,
} from "@/features/renew-visa/enums/employeeApplicationForm";
import { useGetMunicipalityCode } from "@/features/renew-visa/hook/useGetMunicipalityCode";
import { getYearMonthOfTransitionPeriod } from "@/features/renew-visa/utils/getYearMonthOfTransitionPeriod";
import { EMPLOYEE_CAREER_LOCATION, GENDER, RESIDENCE_STATUS, YES_NO_AND_NOT_APPLICABLE_ANSWER, YES_NO_ANSWER } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";
import { convertHalfWidthToFullWidth } from "@/utils/pieces";
import { padZeroIfFive } from "@/utils/string";

type TRenewVisaList = {
  employeeApplication: TRenewVisaEmployeeApplicationDocument;
  companyApplication: TRenewVisaCompanyApplicationDocument;
};

export const useConvertRenewVisaToCsvData = () => {
  const { getMunicipalityCode } = useGetMunicipalityCode();

  const convertRenewVisaToCsvData = (selectedRenewVisaList: TRenewVisaList[]) => {
    const employeeFamilyMembersMaxLength = toNumber(
      selectedRenewVisaList.reduce((maxItems, currentObj) => {
        return toNumber(currentObj.employeeApplication?.employeeFamilyMembers?.length) > toNumber(maxItems.length)
          ? currentObj.employeeApplication?.employeeFamilyMembers
          : maxItems;
      }, [] as TRenewVisaEmployeeApplicationDocumentFamilyMember[])?.length,
    );
    const workingHistoriesMaxLength = toNumber(
      selectedRenewVisaList.reduce((maxItems, currentObj) => {
        return toNumber(currentObj.employeeApplication?.workingHistories?.length) > toNumber(maxItems.length)
          ? currentObj.employeeApplication?.workingHistories
          : maxItems;
      }, [] as TRenewVisaEmployeeApplicationDocumentWorkingHistory[])?.length,
    );

    const csvData = selectedRenewVisaList.map((rv, index) => {
      const employeeFamilyMembersCsvData = [];

      const employeeFamilyMembersArray = rv.employeeApplication.employeeFamilyMembers;
      employeeFamilyMembersArray.length = employeeFamilyMembersMaxLength >= 6 ? employeeFamilyMembersMaxLength : 6;
      for (let i = 0; i < employeeFamilyMembersArray.length; i++) {
        if (employeeFamilyMembersArray[i]) {
          employeeFamilyMembersCsvData.push({
            [`zincSnzkList[${i + 1}].selNationalityAndRegion`]: employeeFamilyMembersArray[i]?.nationality?.nationalRenewVisaCsvCode ?? "",
            [`zincSnzkList[${i + 1}].txtName`]: convertHalfWidthToFullWidth(employeeFamilyMembersArray[i]?.fullName ?? "") ?? "",
            [`zincSnzkList[${i + 1}].selDateOfBirthYear(4),selDateOfBirthMonth(2),selDateOfBirthDay(2)`]: employeeFamilyMembersArray[i]?.birthday
              ? dayjs(employeeFamilyMembersArray[i].birthday).format("YYYYMMDD")
              : "",
            [`zincSnzkList[${i + 1}].selZkgr`]: employeeFamilyMembersArray[i]?.employeeRelationship
              ? employeeFamilyMembersArray[i]?.employeeRelationship?.xLivingRelationshipRenewVisaCsvCode
              : "",
            [`zincSnzkList[${i + 1}].radDukyUm`]:
              employeeFamilyMembersArray[i]?.livingWithEmployee && employeeFamilyMembersArray[i].livingWithEmployee === YES_NO_ANSWER.YES ? "1" : "0",
            [`zincSnzkList[${i + 1}].txtWorkPlaceOrTugkskName`]:
              convertHalfWidthToFullWidth(employeeFamilyMembersArray[i]?.workingLocation ?? "") ?? "",
            [`zincSnzkList[${i + 1}].txtZiryCardNumOrTkeiNum`]: employeeFamilyMembersArray[i]?.residenceCode ?? "",
          });
        } else {
          employeeFamilyMembersCsvData.push({
            [`zincSnzkList[${i + 1}].selNationalityAndRegion`]: "",
            [`zincSnzkList[${i + 1}].txtName`]: "",
            [`zincSnzkList[${i + 1}].selDateOfBirthYear(4),selDateOfBirthMonth(2),selDateOfBirthDay(2)`]: "",
            [`zincSnzkList[${i + 1}].selZkgr`]: "",
            [`zincSnzkList[${i + 1}].radDukyUm`]: "",
            [`zincSnzkList[${i + 1}].txtWorkPlaceOrTugkskName`]: "",
            [`zincSnzkList[${i + 1}].txtZiryCardNumOrTkeiNum`]: "",
          });
        }
      }

      const workingHistoriesCsvData = [];
      const workingHistoriesArray = rv.employeeApplication.workingHistories;
      workingHistoriesArray.length = workingHistoriesMaxLength >= 10 ? workingHistoriesMaxLength : 10;
      for (let i = 0; i < workingHistoriesArray.length; i++) {
        if (workingHistoriesArray[i]) {
          workingHistoriesCsvData.push({
            [`empHisList[${i + 1}].txtWorkPlaceName`]: workingHistoriesArray[i]?.jobName
              ? convertHalfWidthToFullWidth(workingHistoriesArray[i]?.jobName ?? "")
              : "",
            [`empHisList[${i + 1}].selJobStartDateYear`]: workingHistoriesArray[i]?.workingStartYear ?? "",
            [`empHisList[${i + 1}].selJobStartDateMonth`]: workingHistoriesArray[i]?.workingStartMonth?.toString()?.padStart(2, "0") ?? "",
            [`empHisList[${i + 1}].selJobEndDateYear`]: workingHistoriesArray[i]?.workingEndYear ?? "",
            [`empHisList[${i + 1}].selJobEndDateMonth`]: workingHistoriesArray[i]?.workingEndMonth?.toString()?.padStart(2, "0") ?? "",
          });
        } else {
          workingHistoriesCsvData.push({
            [`empHisList[${i + 1}].txtWorkPlaceName`]: "",
            [`empHisList[${i + 1}].selJobStartDateYear`]: "",
            [`empHisList[${i + 1}].selJobStartDateMonth`]: "",
            [`empHisList[${i + 1}].selJobEndDateYear`]: "",
            [`empHisList[${i + 1}].selJobEndDateMonth`]: "",
          });
        }
      }

      const { month: selSpecSkill1TotalZiryPeriodMonth, year: selSpecSkill1TotalZiryPeriodYear } = getYearMonthOfTransitionPeriod(
        rv.employeeApplication?.relatedActivitySpecificSkillTransitionPeriod ?? "",
      );

      return {
        ["No."]: index + 1,
        ["selSnsiShbt"]: "04V",
        ["WCCAS010Dto:selZirySkk"]: "04V1",
        ["WCCBS010Dto:selNationalityAndRegion"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoNationality
            ? rv.employeeApplication.employeeInfoNationality.nationalRenewVisaCsvCode
            : "",
        ["txtName"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoFullName
            ? rv.employeeApplication.employeeInfoFullName.toUpperCase().replaceAll(" ", ",")
            : "",
        ["radSex"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoGender && rv.employeeApplication.employeeInfoGender === GENDER.MALE
            ? "1"
            : "2",
        ["selDateOfBirthYear(4),selDateOfBirthMonth(2),selDateOfBirthDay(2)"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoBirthday
            ? dayjs(rv.employeeApplication.employeeInfoBirthday).format("YYYYMMDD")
            : "",
        ["radSpouse"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoMaritalStatus
            ? rv.employeeApplication.employeeInfoMaritalStatus === EMaritalStatus.MARRIED
              ? "1"
              : "0"
            : "",
        ["txtJob"]: rv.employeeApplication && rv.employeeApplication.employeeInfoCareer ? `${rv.employeeApplication.employeeInfoCareer}` : "",
        ["txtHomeCountryAddress"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoPassportPermanentAddress
            ? `${convertHalfWidthToFullWidth(rv.employeeApplication.employeeInfoPassportPermanentAddress)}`
            : "",
        ["WCCBS010Dto:hdnSearchedAddress"]: padZeroIfFive(getMunicipalityCode(rv.employeeApplication.employeeInfoMunicipality ?? "")) ?? "",
        ["txtDetailHomeAddress"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoAddress
            ? convertHalfWidthToFullWidth(rv.employeeApplication.employeeInfoAddress)
            : "",
        ["txtTelNum"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoTelNumber
            ? rv.employeeApplication.employeeInfoTelNumber.replaceAll("-", "")
            : "",
        ["txtCelPhoneNum"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoMobileNumber ? rv.employeeApplication.employeeInfoMobileNumber : "",
        ["txtMailAddress"]: rv.employeeApplication && rv.employeeApplication.employeeInfoEmail ? rv.employeeApplication.employeeInfoEmail : "",
        ["txtPassportNum"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoPassportCode ? rv.employeeApplication.employeeInfoPassportCode : "",
        ["selPassportExpirationYear(4),selPassportExpirationMonth(2),selPassportExpirationDay(2)"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoPassportExpiryDate
            ? dayjs(rv.employeeApplication.employeeInfoPassportExpiryDate).format("YYYYMMDD")
            : "",
        ["radHnziUm1"]:
          rv.employeeApplication &&
          rv.employeeApplication.employeeInfoHasReceivedPunishmentForCrime &&
          rv.employeeApplication.employeeInfoHasReceivedPunishmentForCrime === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtHnziNiyu"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoPunishmentDetailForCrime
            ? rv.employeeApplication.employeeInfoPunishmentDetailForCrime
            : "",
        ["radZincSnzkUm1"]:
          rv.employeeApplication &&
          rv.employeeApplication.employeeInfoLivingWithDependentPerson &&
          rv.employeeApplication.employeeInfoLivingWithDependentPerson === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ...Object.assign({}, ...employeeFamilyMembersCsvData),
        ["WCCBS010Dto:txtTaxPay"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoRecentResidenceTax
            ? rv.employeeApplication.employeeInfoRecentResidenceTax
            : "",
        ["radZiryCardJryHuhu"]:
          rv.employeeApplication &&
          rv.employeeApplication.employeeInfoResidenceCardReceiveMethod &&
          rv.employeeApplication.employeeInfoResidenceCardReceiveMethod === EResidenceCardReceiptMethod.MAIL
            ? "01"
            : "02",
        ["selJryuKnsh"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoResidenceCardReceiveGovernmentCounter
            ? rv.employeeApplication.employeeInfoResidenceCardReceiveGovernmentCounter.bmOfficeRenewVisaCsvCode
            : "",
        ["txtTucSusnMailAddress"]: rv.employeeApplication && rv.employeeApplication.employeeInfoEmail ? rv.employeeApplication.employeeInfoEmail : "",
        ["selCurrentZirySkk"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoTwoResidenceStatus
            ? rv.employeeApplication.employeeInfoTwoResidenceStatus.residenceStatusRenewVisaCsvCode
            : "",
        ["WCCCS020Dto:selZiryPeriod"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoTwoResidencePeriod
            ? rv.employeeApplication.employeeInfoTwoResidencePeriod.residencePeriodRenewVisaCsvCode
            : "",
        ["selExpirationDateYear(4),selExpirationDateMonth(2),selExpirationDateDay(2)"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoTwoResidenceExpiryDate
            ? dayjs(rv.employeeApplication.employeeInfoTwoResidenceExpiryDate).format("YYYYMMDD")
            : "",
        ["WCCCS020Dto:txtZiryCardNum"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoTwoResidenceCode ? rv.employeeApplication.employeeInfoTwoResidenceCode : "",
        ["txtEdNumEng"]: "",
        ["txtEdNumNum"]: "",
        ["selNewZiryPeriod"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoTwoResidenceDesiredPeriod
            ? rv.employeeApplication.employeeInfoTwoResidenceDesiredPeriod.desiredResidencePeriodRenewVisaCsvCode
            : "",
        ["WCCCS020Dto:txtKusnReason"]:
          rv.employeeApplication && rv.employeeApplication.employeeInfoTwoResidenceChangeReason
            ? rv.employeeApplication.employeeInfoTwoResidenceChangeReason
            : "",
        ["txtDirnnName"]: "",
        ["txtDirnnZkgr"]: "",
        ["hdnSearchedDirnnAddress"]: "",
        ["txtDetailDirnnAddress"]: "",
        ["txtDirnnTelNum"]: "",
        ["txtDirnnCelPhoneNum"]: "",
        ["txtTrtgshName"]: convertHalfWidthToFullWidth(rv.employeeApplication?.employeeInfoTwoRasImmiFullName ?? "") ?? "",
        ["chkDuiStmi"]: "1",
        ["selSkllHyokKbnCode"]:
          rv.employeeApplication &&
          rv.employeeApplication.relatedActivitySkillCertificationOne &&
          rv.employeeApplication.relatedActivitySkillCertificationOne === EEmployeeCareerSkillCertificationOne.FIELD_SPECIFIC_OPERATION_POLICY
            ? "01"
            : "02",
        ["selSkllPassExamCode1"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivitySkillExamOne
            ? rv.employeeApplication.relatedActivitySkillExamOne.cfSkillTestRenewVisaCsvCode
            : "",
        ["selSkllPassExamCode2"]: "",
        ["selSkllPassExamCode3"]: "",
        ["txtSkllOtheHyokHohoFr"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivitySkillOtherEvaluation
            ? rv.employeeApplication.relatedActivitySkillOtherEvaluation
            : "",
        ["selJpnNrkHyokKbnCode"]:
          rv.employeeApplication &&
          rv.employeeApplication.relatedActivityJapaneseCertificationTwo &&
          rv.employeeApplication.relatedActivityJapaneseCertificationTwo === EEmployeeCareerJapaneseCertification.FIELD_SPECIFIC_OPERATION_POLICY
            ? "01"
            : "02",
        ["selJpnNrkPassExamCode1"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityJapaneseExamTwo
            ? rv.employeeApplication.relatedActivityJapaneseExamTwo.ckLanguageTestRenewVisaCsvCode
            : "",
        ["selJpnNrkPassExamCode2"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityJapaneseExamThree
            ? rv.employeeApplication.relatedActivityJapaneseExamThree.ckLanguageTestRenewVisaCsvCode
            : "",
        ["selJpnNrkPassExamCode3"]: "",
        ["txtJpnNrkOtheHyokHohoFr"]: "",
        ["selGnjs2GnjsTypeCode1"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityMajor
            ? rv.employeeApplication.relatedActivityMajor.coMajorRenewVisaCsvCode
            : "",
        ["selGnjs2GnjsDetail12Code1"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityJobType
            ? rv.employeeApplication.relatedActivityJobType.cpJobRenewVisaCsvCode
            : "",
        ["selGnjs2SyroSymiKbnCode1"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityCompletionProof
            ? rv.employeeApplication.relatedActivityCompletionProof === EEmployeeCareerCompletionProof.PASSING_THREE_LEVEL_SKILL_OR_EQUIVALENT
              ? "01"
              : "02"
            : "",
        ["selGnjs2SyokusCode2"]: "",
        ["selGnjs2GnjsDetail12Code2"]: "",
        ["selGnjs2SyroSymiKbnCode2"]: "",
        ["selSpecSkill1TotalZiryPeriodYear"]: selSpecSkill1TotalZiryPeriodYear.toString(),
        ["selSpecSkill1TotalZiryPeriodMonth"]: selSpecSkill1TotalZiryPeriodMonth.toString(),
        ["radGykyIyakUmFlg"]:
          rv.employeeApplication &&
          rv.employeeApplication.relatedActivityHasCollectionForSpecificSkillOne &&
          rv.employeeApplication.relatedActivityHasCollectionForSpecificSkillOne === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtGykyTsOrKrKnName"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityCollectionOrManagementAgencyName
            ? convertHalfWidthToFullWidth(rv.employeeApplication.relatedActivityCollectionOrManagementAgencyName)
            : "",
        ["txtGykyTsOrKrZs"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityCollectionAmount
            ? rv.employeeApplication.relatedActivityCollectionAmount
            : "",
        ["radGykyKtdzbhGiUmFlg"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees
            ? rv.employeeApplication.relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees ===
              YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.employeeApplication.relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES
                ? "1"
                : "0"
            : "",
        ["txtGykyKtdzbhGiGkknName"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityForeignAgencyName
            ? convertHalfWidthToFullWidth(rv.employeeApplication.relatedActivityForeignAgencyName)
            : "",
        ["txtGykyKtdzbhGiShgUtwk"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityForeignAgencyFee
            ? rv.employeeApplication.relatedActivityForeignAgencyFee
            : "",
        ["radJpKtdkrTtdkUmFlg"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityHasComplianceWithProceduresInHomeCountry
            ? rv.employeeApplication.relatedActivityHasComplianceWithProceduresInHomeCountry === YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.employeeApplication.relatedActivityHasComplianceWithProceduresInHomeCountry === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES
                ? "1"
                : "0"
            : "",
        ["radJpTgkftGiUmFlg"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityWhetherRegularPayments
            ? rv.employeeApplication.relatedActivityWhetherRegularPayments === YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.employeeApplication.relatedActivityWhetherRegularPayments === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES
                ? "1"
                : "0"
            : "",
        ["radJpGnjsUmFlg"]:
          rv.employeeApplication?.employeeInfoTwoResidenceStatusId?.toString() === RESIDENCE_STATUS.SPECIFIED_SKILL_NO1.toString()
            ? ""
            : rv.employeeApplication?.relatedActivityHasEffortToTransferSkillsToHomeCountry === YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.employeeApplication?.relatedActivityHasEffortToTransferSkillsToHomeCountry === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES
                ? "1"
                : "0",
        ["radSsnTktsgbyKjnTgUmFlg"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityHasComplianceWithIndustrySpecificCriteria
            ? rv.employeeApplication.relatedActivityHasComplianceWithIndustrySpecificCriteria === YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.employeeApplication.relatedActivityHasComplianceWithIndustrySpecificCriteria === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES
                ? "1"
                : "0"
            : "",
        ...Object.assign({}, ...workingHistoriesCsvData),
        ["selGykyPeriodFromYear(4),selGykyPeriodFromMonth(2),selGykyPeriodFromDay(2)"]:
          rv.employeeApplication && rv.employeeApplication.contractStartDate
            ? dayjs(rv.employeeApplication.contractStartDate).format("YYYYMMDD")
            : "",
        ["selGykyPeriodToYear(4),selGykyPeriodToMonth(2),selGykyPeriodToDay(2)"]:
          rv.employeeApplication && rv.employeeApplication.contractEndDate ? dayjs(rv.employeeApplication.contractEndDate).format("YYYYMMDD") : "",
        ["selKykySngoBnyaCode1"]:
          rv.employeeApplication && rv.employeeApplication.contractSpecificIndustry
            ? rv.employeeApplication.contractSpecificIndustry.fgSpecificIndustryRenewVisaCsvCode
            : "",
        ["selKykyGyoumuKbnCode1"]:
          rv.employeeApplication && rv.employeeApplication.contractSpecificBusinessClassification
            ? rv.employeeApplication.contractSpecificBusinessClassification.fhSpecificBusinessClassificationRenewVisaCsvCode
            : "",
        ["selKykySngoBnyaCode2"]:
          rv.employeeApplication && rv.employeeApplication.contractSpecificIndustryTwo
            ? rv.employeeApplication.contractSpecificIndustryTwo.fgSpecificIndustryRenewVisaCsvCode
            : "",
        ["selKykyGyoumuKbnCode2"]:
          rv.employeeApplication && rv.employeeApplication.contractSpecificBusinessClassificationTwo
            ? rv.employeeApplication.contractSpecificBusinessClassificationTwo.fhSpecificBusinessClassificationRenewVisaCsvCode
            : "",
        ["selKykySngoBnyaCode3"]:
          rv.employeeApplication && rv.employeeApplication.contractSpecificIndustryThree
            ? rv.employeeApplication.contractSpecificIndustryThree.fgSpecificIndustryRenewVisaCsvCode
            : "",
        ["selKykyGyoumuKbnCode3"]:
          rv.employeeApplication && rv.employeeApplication.contractSpecificBusinessClassificationThree
            ? rv.employeeApplication.contractSpecificBusinessClassificationThree.fhSpecificBusinessClassificationRenewVisaCsvCode
            : "",
        ["selKykyMainSyokusCode"]:
          rv.employeeApplication && rv.employeeApplication.contractMainOccupation
            ? rv.employeeApplication.contractMainOccupation.fmMainOccupationRenewVisaCsvCode
            : "",
        ["selKykyOtheSyokusCode1"]: "",
        ["selKykyOtheSyokusCode2"]: "",
        ["selKykyOtheSyokusCode3"]: "",
        ["txtKykyShoteiRodoJikanSu"]:
          rv.employeeApplication && rv.employeeApplication.contractWeeklyWorkingHours
            ? Math.floor(rv.employeeApplication.contractWeeklyWorkingHours)
            : 0,
        ["radKykykRdTimeDtUmFlg"]:
          rv.employeeApplication &&
          rv.employeeApplication.whetherEquivalentWorkingHoursToRegularEmployee &&
          rv.employeeApplication.whetherEquivalentWorkingHoursToRegularEmployee === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtKykyMonthHoshKngk"]:
          rv.employeeApplication && rv.employeeApplication.contractMonthlySalary ? rv.employeeApplication.contractMonthlySalary : 0,
        ["txtKykykJphtGtkk"]:
          rv.employeeApplication && rv.employeeApplication.contractMonthlySalaryForJapanesePerson
            ? rv.employeeApplication.contractMonthlySalaryForJapanesePerson
            : "",
        ["radKykykJphtijGtkkUmFlg"]:
          rv.employeeApplication &&
          rv.employeeApplication.compensationEqualOrAboveJapaneseStandard &&
          rv.employeeApplication.compensationEqualOrAboveJapaneseStandard === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radKykyHoshPayCode"]:
          rv.employeeApplication &&
          rv.employeeApplication.contractSalaryPaymentMethod &&
          rv.employeeApplication.contractSalaryPaymentMethod === EPaymentMethod.CASH
            ? "01"
            : "02",
        ["radHphtKtnrUmFlg"]:
          rv.employeeApplication &&
          rv.employeeApplication.contractIsDiscriminatedForBeingForeigner &&
          rv.employeeApplication.contractIsDiscriminatedForBeingForeigner === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtHphtKtnrDetail"]:
          rv.employeeApplication && rv.employeeApplication.contractIsDiscriminatedForBeingForeignerDetail
            ? rv.employeeApplication.contractIsDiscriminatedForBeingForeignerDetail
            : "",
        ["radYkkkUmFlg"]:
          rv.employeeApplication &&
          rv.employeeApplication.contractHasPaidLeaveForReturningHomeTemporarily &&
          rv.employeeApplication.contractHasPaidLeaveForReturningHomeTemporarily === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTktsgbyKjnUmFlg"]:
          rv.employeeApplication && rv.employeeApplication.contractHasComplianceWithEmploymentStandardsInSpecificIndustry
            ? rv.employeeApplication.contractHasComplianceWithEmploymentStandardsInSpecificIndustry ===
              YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.employeeApplication.contractHasComplianceWithEmploymentStandardsInSpecificIndustry === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES
                ? "1"
                : "0"
            : "",
        ["radGykykRhodUmFlg"]:
          rv.employeeApplication &&
          rv.employeeApplication.contractHasProvisionForTravelExpensesWhenLeave &&
          rv.employeeApplication.contractHasProvisionForTravelExpensesWhenLeave === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radKkjkHascUmFlg"]:
          rv.employeeApplication &&
          rv.employeeApplication.complianceWithStandardsForProperResidenceInSpecificIndustry &&
          rv.employeeApplication.complianceWithStandardsForProperResidenceInSpecificIndustry === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTktsgbyKjnTgUmFlg"]:
          rv.employeeApplication && rv.employeeApplication.contractHasSupportForeignerHealthAndLivingConditions
            ? rv.employeeApplication.contractHasSupportForeignerHealthAndLivingConditions === YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.employeeApplication.contractHasSupportForeignerHealthAndLivingConditions === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES
                ? "1"
                : "0"
            : "",
        ["txtHksName"]: convertHalfWidthToFullWidth(rv.employeeApplication?.dispatchingOrganizations?.at(0)?.organizationName ?? "") ?? "",
        ["txtHksHujnNo"]: rv.employeeApplication?.dispatchingOrganizations?.at(0)?.organizationIdentificationNumber ?? "",

        ["hdnSearchHksAddressCode"]: padZeroIfFive(rv.employeeApplication?.dispatchingOrganizations?.at(0)?.organizationMunicipality ?? "") ?? "",
        ["txtDetailHksAddress"]:
          convertHalfWidthToFullWidth(rv.employeeApplication?.dispatchingOrganizations?.at(0)?.organizationAddress ?? "") ?? "",
        ["txtHksTel"]: rv.employeeApplication?.dispatchingOrganizations?.at(0)?.organizationTelNumber?.replaceAll("-", "") ?? "",
        ["txtHksDhsName"]: convertHalfWidthToFullWidth(rv.employeeApplication?.dispatchingOrganizations?.at(0)?.representativeName ?? "") ?? "",
        ["selHakenPeriodFromYear(4),selHakenPeriodFromMonth(2),selHakenPeriodFromDay(2)"]: rv.employeeApplication?.dispatchingOrganizations?.at(0)
          ?.startDate
          ? dayjs(rv.employeeApplication?.dispatchingOrganizations?.at(0)?.startDate).format("YYYYMMDD")
          : "",
        ["selHakenPeriodToYear(4),selHakenPeriodToMonth(2),selHakenPeriodToDay(2)"]: rv.employeeApplication?.dispatchingOrganizations?.at(0)?.endDate
          ? dayjs(rv.employeeApplication?.dispatchingOrganizations?.at(0)?.endDate).format("YYYYMMDD")
          : "",
        ["txtSkgsksName"]: "",
        ["txtSkgsksHujnNo"]: "",
        ["hdnSearchSkgsksAddressCode"]: "",
        ["txtDetailSkgsksAddress"]: "",
        ["txtSkgsksTel"]: "",
        ["txtSkgsksKkNo"]: "",
        ["selSkgsksAceptYmdYear(4),selSkgsksAceptYmdMonth(2),selSkgsksAceptYmdDay(2)"]: "",
        ["txtToriSzknName"]: "",
        ["selToriRegion"]: "",
        ["hdnSearchToriAddressCode"]: "",
        ["txtDetailToriAddress"]: "",
        ["txtToriTel"]: "",
        ["txtTtgnSzknName"]:
          rv.companyApplication && rv.companyApplication.companyName ? convertHalfWidthToFullWidth(rv.companyApplication.companyName) : "",
        ["txtTtgnszknHujnNo"]:
          rv.companyApplication && rv.companyApplication.companyIdentificationNumber ? rv.companyApplication.companyIdentificationNumber : "",
        ["selTtgnMainGsyuCode"]:
          rv.companyApplication && rv.companyApplication.mainIndustry ? rv.companyApplication.mainIndustry.gzMainIndustryRenewVisaCsvCode : "",
        ["txtSzknJgySnta"]: rv.companyApplication && rv.companyApplication.mainIndustryOther ? rv.companyApplication.mainIndustryOther : "",
        ["selTtgnOtheGsyuCode1"]: "",
        ["txtSzknJgySntaTwo"]: "",
        ["selTtgnOtheGsyuCode2"]: "",
        ["txtSzknJgySntaThr"]: "",
        ["hdnSearchTtgnAddressCode"]: padZeroIfFive(getMunicipalityCode(rv.companyApplication?.municipality ?? "")) ?? "",
        ["txtDetailTtgnAddress"]:
          rv.companyApplication && rv.companyApplication.address ? convertHalfWidthToFullWidth(rv.companyApplication.address) : "",
        ["txtTtgnSzknTel"]: rv.companyApplication && rv.companyApplication.telNumber ? rv.companyApplication.telNumber.replaceAll("-", "") : "",
        ["txtTtgnszknCapitalKngk"]: toNumber(rv.companyApplication.capital),
        ["txtTtgnszknYearUragKngk"]:
          rv.companyApplication && rv.companyApplication.annualRevenue ? Math.floor(rv.companyApplication.annualRevenue / 10000) : "",
        ["txtTtgnszknStaffNinzu"]: rv.companyApplication && rv.companyApplication.employeeCount ? rv.companyApplication.employeeCount : "",
        ["txtTtgnDhyoNameKnj"]:
          rv.companyApplication && rv.companyApplication.representativeName
            ? convertHalfWidthToFullWidth(rv.companyApplication.representativeName)
            : "",
        ["txtTtgnszknJgsName"]:
          rv.companyApplication && rv.companyApplication.branchName ? convertHalfWidthToFullWidth(rv.companyApplication.branchName) : "",
        ["hdnSearchTtgnszknJgsAddressCode"]: padZeroIfFive(getMunicipalityCode(rv.companyApplication?.branchMunicipality ?? "")) ?? "",
        ["txtDetailTtgnszknJgsAddress"]:
          rv.companyApplication && rv.companyApplication.branchAddress ? convertHalfWidthToFullWidth(rv.companyApplication.branchAddress) : "",
        ["radTtgnKnkoKosiHkenUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.companyHasComplianceWithHealthAndWelfarePensionInsurance &&
          rv.companyApplication.companyHasComplianceWithHealthAndWelfarePensionInsurance === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTtgnRosiKoyoHkenUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.complianceWithWorkersCompensationAndEmploymentInsurance &&
          rv.companyApplication.complianceWithWorkersCompensationAndEmploymentInsurance === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnRodoHkenNo"]:
          rv.companyApplication && rv.companyApplication.employmentInsuranceNumber ? rv.companyApplication.employmentInsuranceNumber : "",
        ["radTtgnszknKtihUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws &&
          rv.companyApplication.whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszknKtihDetail"]:
          rv.companyApplication && rv.companyApplication.violationsOfLaborSocialInsuranceAndTaxLawsDetail
            ? rv.companyApplication.violationsOfLaborSocialInsuranceAndTaxLawsDetail
            : "",
        ["radTtgnszknHjhrsUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherInvoluntaryDismissalOfWorkersInSimilarRoles &&
          rv.companyApplication.whetherInvoluntaryDismissalOfWorkersInSimilarRoles === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszknHjhrsDetail"]:
          rv.companyApplication && rv.companyApplication.involuntaryDismissalOfWorkersInSimilarRolesDetail
            ? rv.companyApplication.involuntaryDismissalOfWorkersInSimilarRolesDetail
            : "",
        ["radTtgnszknYkefmsUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherMissingForeignWorkersDueToOrgIsFault &&
          rv.companyApplication.whetherMissingForeignWorkersDueToOrgIsFault === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszknYkefmsDetail"]:
          rv.companyApplication && rv.companyApplication.involuntaryDismissalOfWorkersInSimilarRolesDetail
            ? rv.companyApplication.involuntaryDismissalOfWorkersInSimilarRolesDetail
            : "",
        ["radTtgnszttSkUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel &&
          rv.companyApplication.whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszttSkDetail"]:
          rv.companyApplication && rv.companyApplication.criminalPenaltiesForLawViolationsByOrgOrPersonnelDetail
            ? rv.companyApplication.criminalPenaltiesForLawViolationsByOrgOrPersonnelDetail
            : "",
        ["radTtgnszttSgUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel &&
          rv.companyApplication.whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszttSgDetail"]:
          rv.companyApplication && rv.companyApplication.mentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelDetail
            ? rv.companyApplication.mentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelDetail
            : "",
        ["radTtgnszttHshktFkeUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel &&
          rv.companyApplication.whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszttHshktFkeDetail"]:
          rv.companyApplication && rv.companyApplication.unrehabilitatedBankruptcyOfOrgOrPersonnelDetail
            ? rv.companyApplication.unrehabilitatedBankruptcyOfOrgOrPersonnelDetail
            : "",
        ["radTtgnszttJsnttkUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherCancellationOfTrainingCertification &&
          rv.companyApplication.whetherCancellationOfTrainingCertification === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszttJsnttkDetail"]:
          rv.companyApplication && rv.companyApplication.cancellationOfTrainingCertificationDetail
            ? rv.companyApplication.cancellationOfTrainingCertificationDetail
            : "",
        ["radTtgnszttNttkyiUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification &&
          rv.companyApplication.whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszttNttkyiDetail"]:
          rv.companyApplication && rv.companyApplication.formerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationDetail
            ? rv.companyApplication.formerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationDetail
            : "",
        ["radTtgnszttFtkiUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherUnfairActsRelatedToImmigrationLaborLaws &&
          rv.companyApplication.whetherUnfairActsRelatedToImmigrationLaborLaws === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszttFtkiDetail"]:
          rv.companyApplication && rv.companyApplication.unfairActsRelatedToImmigrationLaborLawsDetail
            ? rv.companyApplication.unfairActsRelatedToImmigrationLaborLawsDetail
            : "",
        ["radTtgnszttBrdiUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherAffiliationWithOrganizedCrime &&
          rv.companyApplication.whetherAffiliationWithOrganizedCrime === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszttBrdiDetail"]:
          rv.companyApplication && rv.companyApplication.affiliationWithOrganizedCrimeDetail
            ? rv.companyApplication.affiliationWithOrganizedCrimeDetail
            : "",
        ["radTtgnszttDljgtUmFlg"]:
          rv.companyApplication && rv.companyApplication.whetherLegalRepresentativeIsEligibility
            ? rv.companyApplication.whetherLegalRepresentativeIsEligibility === YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.companyApplication.whetherLegalRepresentativeIsEligibility === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES
                ? "1"
                : "0"
            : "",
        ["txtTtgnszttDljgtDetail"]:
          rv.companyApplication && rv.companyApplication.legalRepresentativeIsEligibilityDetail
            ? rv.companyApplication.legalRepresentativeIsEligibilityDetail
            : "",
        ["radTtgnszknShsBrdiUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherOrganizedCrimeControlOverBusiness &&
          rv.companyApplication.whetherOrganizedCrimeControlOverBusiness === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszknShsBrdiDetail"]:
          rv.companyApplication && rv.companyApplication.organizedCrimeControlOverBusinessDetail
            ? rv.companyApplication.organizedCrimeControlOverBusinessDetail
            : "",
        ["radTtgnszknKdkysnetokUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherRetentionOfActivityDocuments &&
          rv.companyApplication.whetherRetentionOfActivityDocuments === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTtgnszknKykytkUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements &&
          rv.companyApplication.whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszknKykytkDetail"]:
          rv.companyApplication && rv.companyApplication.awarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsDetail
            ? rv.companyApplication.awarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsDetail
            : "",
        ["radTtgnszknShkytkUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherPenaltyClausesForBreachOfEmploymentContract &&
          rv.companyApplication.whetherPenaltyClausesForBreachOfEmploymentContract === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszknShkytkDetail"]:
          rv.companyApplication && rv.companyApplication.penaltyClausesForBreachOfEmploymentContractDetail
            ? rv.companyApplication.penaltyClausesForBreachOfEmploymentContractDetail
            : "",
        ["radTtgnszknHyftUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherNoCostBurdenOnWorkerForSpecifiedSkilledWorkerSupport &&
          rv.companyApplication.whetherNoCostBurdenOnWorkerForSpecifiedSkilledWorkerSupport === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTtgnszknGkkjnTsUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherConditionsApplyForStaffingTargets &&
          rv.companyApplication.whetherConditionsApplyForStaffingTargets === YES_NO_ANSWER.YES
            ? "1"
            : "",
        ["chkHakenTtSngoGyomuAriFlg"]:
          rv.companyApplication &&
          rv.companyApplication.conditionsApplyForStaffingTargetsDetail &&
          rv.companyApplication.conditionsApplyForStaffingTargetsDetail.includes(
            ERenewVisaConditionsApplyForStaffingTarget.RELEVANT_INDUSTRY_WORK_AT_DISPATCH_SITE,
          )
            ? "1"
            : "",
        ["txtTtgnszknKrgmDetail"]:
          rv.companyApplication && rv.companyApplication.relevantIndustryWorkAtDispatchSiteDetail
            ? rv.companyApplication.relevantIndustryWorkAtDispatchSiteDetail
            : "",
        ["chkHakenCapitalSyshAriFlg"]:
          rv.companyApplication &&
          rv.companyApplication.conditionsApplyForStaffingTargetsDetail &&
          rv.companyApplication.conditionsApplyForStaffingTargetsDetail.includes(
            ERenewVisaConditionsApplyForStaffingTarget.MAJORITY_CAPITAL_INVESTMENT_BY_INDUSTRY_RELATED_ENTITY,
          )
            ? "1"
            : "",
        ["txtTtgnszknKhssDetail"]:
          rv.companyApplication && rv.companyApplication.majorityCapitalInvestmentByIndustryRelatedEntityDetail
            ? rv.companyApplication.majorityCapitalInvestmentByIndustryRelatedEntityDetail
            : "",
        ["chkHakenGyomKanyoAriFlg"]:
          rv.companyApplication &&
          rv.companyApplication.conditionsApplyForStaffingTargetsDetail &&
          rv.companyApplication.conditionsApplyForStaffingTargetsDetail.includes(
            ERenewVisaConditionsApplyForStaffingTarget.SUBSTANTIAL_INVOLVEMENT_BY_INDUSTRY_RELATED_ENTITY,
          )
            ? "1"
            : "",
        ["txtTtgnszknSikkouDetail"]:
          rv.companyApplication && rv.companyApplication.substantialInvolvementByIndustryRelatedEntityDetail
            ? rv.companyApplication.substantialInvolvementByIndustryRelatedEntityDetail
            : "",
        ["chkHakenTkho16no5no1Flg"]:
          rv.companyApplication &&
          rv.companyApplication.conditionsApplyForStaffingTargetsDetail &&
          rv.companyApplication.conditionsApplyForStaffingTargetsDetail.includes(
            ERenewVisaConditionsApplyForStaffingTarget.AGRICULTURAL_WORK_IN_DESIGNATED_STRATEGIC_ZONE,
          )
            ? "1"
            : "",
        ["radTtgnszknHksgtUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherHostCompanyComplianceWithSections &&
          rv.companyApplication.whetherHostCompanyComplianceWithSections === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszknHksgtDetail"]:
          rv.companyApplication && rv.companyApplication.hostCompanyComplianceWithSectionsDetail
            ? rv.companyApplication.hostCompanyComplianceWithSectionsDetail
            : "",
        ["radTtgnszknRshkUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherWorkerIsCompensationInsuranceEnrollment &&
          rv.companyApplication.whetherWorkerIsCompensationInsuranceEnrollment === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszknRshkDetail"]:
          rv.companyApplication && rv.companyApplication.workerIsCompensationInsuranceEnrollmentDetail
            ? rv.companyApplication.workerIsCompensationInsuranceEnrollmentDetail
            : "",
        ["radTtgnszknKzktssbUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherAdequateSystemForContinuedEmploymentContractFulfillment &&
          rv.companyApplication.whetherAdequateSystemForContinuedEmploymentContractFulfillment === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTtgnszknKyknSrknUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherPaymentOfWagesViaBankOrVerifiableMethodWithReporting &&
          rv.companyApplication.whetherPaymentOfWagesViaBankOrVerifiableMethodWithReporting === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTtgnszknKkjkjtgUmFlg"]:
          rv.companyApplication && rv.companyApplication.whetherComplianceWithIndustrySpecificStandardsForContractFulfillment
            ? rv.companyApplication.whetherComplianceWithIndustrySpecificStandardsForContractFulfillment ===
              YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.companyApplication.whetherComplianceWithIndustrySpecificStandardsForContractFulfillment === YES_NO_ANSWER.YES
                ? "1"
                : "0"
            : "",
        ["txtTtgnszknSeSknsName"]:
          rv.companyApplication && rv.companyApplication.supportSupervisor
            ? convertHalfWidthToFullWidth(rv.companyApplication.supportSupervisor)
            : "",
        ["txtTtgnszknSeSknsSzyksk"]:
          rv.companyApplication && rv.companyApplication.supportSupervisorRole
            ? convertHalfWidthToFullWidth(rv.companyApplication.supportSupervisorRole)
            : "",
        ["radTtgnszknSeSknsSnUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherAppointmentOfSupportSupervisorFromStaff &&
          rv.companyApplication.whetherAppointmentOfSupportSupervisorFromStaff === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszknSeTtsName"]:
          rv.companyApplication && rv.companyApplication.supportPersonnel ? convertHalfWidthToFullWidth(rv.companyApplication.supportPersonnel) : "",
        ["txtTtgnszknSeTtsSzyksk"]:
          rv.companyApplication && rv.companyApplication.supportPersonnelRole
            ? convertHalfWidthToFullWidth(rv.companyApplication.supportPersonnelRole)
            : "",
        ["radTtgnszknSeTtsSnUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherAppointmentOfSupportPersonnelFromStaff &&
          rv.companyApplication.whetherAppointmentOfSupportPersonnelFromStaff === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTtgnszknKkkrUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherConditionsApplyForSupportPlanDelegation &&
          rv.companyApplication.whetherConditionsApplyForSupportPlanDelegation === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["chkTtgnszknCckzrsUkirkrjsAriFlg"]:
          rv.companyApplication &&
          rv.companyApplication.conditionsApplyForSupportPlanDelegationDetail &&
          rv.companyApplication.conditionsApplyForSupportPlanDelegationDetail.includes(
            ERenewVisaConditionsApplyForSupportPlanDelegation.TRACK_RECORD_OF_PROPER_MANAGEMENT_OF_SPECIFIC_VISA_HOLDERS,
          )
            ? "1"
            : "0",
        ["chkTtgnszknCckzrsSdkkAriFlg"]:
          rv.companyApplication &&
          rv.companyApplication.conditionsApplyForSupportPlanDelegationDetail &&
          rv.companyApplication.conditionsApplyForSupportPlanDelegationDetail.includes(
            ERenewVisaConditionsApplyForSupportPlanDelegation.EXPERIENCE_IN_SUPPORTING_SPECIFIC_VISA_HOLDERS,
          )
            ? "1"
            : "",
        ["chkTtgnszknJssjjAriFlg"]:
          rv.companyApplication &&
          rv.companyApplication.conditionsApplyForSupportPlanDelegationDetail &&
          rv.companyApplication.conditionsApplyForSupportPlanDelegationDetail.includes(
            ERenewVisaConditionsApplyForSupportPlanDelegation.ABILITY_TO_PROPERLY_PERFORM_OTHER_SUPPORT_DUTIES,
          )
            ? "1"
            : "",
        ["txtTtgnszknJssjjDetail"]:
          rv.companyApplication && rv.companyApplication.conditionsApplyForSupportPlanDelegationOtherDetail
            ? rv.companyApplication.conditionsApplyForSupportPlanDelegationOtherDetail
            : "",
        ["radTtgnszknGkkjnrkggtsUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherSupportSystemInUnderstandingLanguageIsAvailable &&
          rv.companyApplication.whetherSupportSystemInUnderstandingLanguageIsAvailable === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTtgnszknSekysnetokUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherRetentionOfSupportDocumentation &&
          rv.companyApplication.whetherRetentionOfSupportDocumentation === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTtgnszknSekkjsUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherSupportPersonnelAreNeutralInImplementation &&
          rv.companyApplication.whetherSupportPersonnelAreNeutralInImplementation === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTtgnszknGkkjnseoktrUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherNeglectOfSupportPlanOccurredInPastFiveYears &&
          rv.companyApplication.whetherNeglectOfSupportPlanOccurredInPastFiveYears === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["txtTtgnszknGkkjnseoktrDetail"]:
          rv.companyApplication && rv.companyApplication.neglectOfSupportPlanOccurredInPastFiveYearsDetail
            ? rv.companyApplication.neglectOfSupportPlanOccurredInPastFiveYearsDetail
            : "",
        ["radTtgnszknMdjssUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherRegularMeetingSystemWithSupervisorsIsInPlace &&
          rv.companyApplication.whetherRegularMeetingSystemWithSupervisorsIsInPlace === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radTtgnszknIgtktKjtgUmFlg"]:
          rv.employeeApplication?.employeeInfoTwoResidenceStatusId?.toString() === RESIDENCE_STATUS.SPECIFIED_SKILL_NO2.toString()
            ? ""
            : rv.companyApplication?.whetherComplianceWithIndustryStandardsIsEnsured === YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.companyApplication?.whetherComplianceWithIndustryStandardsIsEnsured === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES
                ? "1"
                : "0",
        ["radIggseSeUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherTransportationToPortOrAirportIsProvided &&
          rv.companyApplication.whetherTransportationToPortOrAirportIsProvided === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseJskkhUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherSupportForSecuringAppropriateHousingIsProvided &&
          rv.companyApplication.whetherSupportForSecuringAppropriateHousingIsProvided === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseKykseUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherSupportForOpeningBankAccountsAndContractsIsProvided &&
          rv.companyApplication.whetherSupportForOpeningBankAccountsAndContractsIsProvided === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseKkggjssUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherInformationOnLivingInJapanIsProvidedInUnderstandableLanguage &&
          rv.companyApplication.whetherInformationOnLivingInJapanIsProvidedInUnderstandableLanguage === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseDkscUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherAccompanimentForProceduresIsProvidedIfNeeded &&
          rv.companyApplication.whetherAccompanimentForProceduresIsProvidedIfNeeded === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseNhggkstkUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherOpportunitiesForJapaneseLanguageLearningAreProvided &&
          rv.companyApplication.whetherOpportunitiesForJapaneseLanguageLearningAreProvided === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseKjtoscUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherTimelyResponseToConsultationsOrComplaintsIsEnsured &&
          rv.companyApplication.whetherTimelyResponseToConsultationsOrComplaintsIsEnsured === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseKrsksnsenUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherSupportForPromotingExchangeBetweenForeignersAndJapaneseIsProvided &&
          rv.companyApplication.whetherSupportForPromotingExchangeBetweenForeignersAndJapaneseIsProvided === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseTsseUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherJobChangeSupportIsProvidedForContractTermination &&
          rv.companyApplication.whetherJobChangeSupportIsProvidedForContractTermination === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseGsknTsuhouUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherRegularMeetingsWithSupervisorsAreConducted &&
          rv.companyApplication.whetherRegularMeetingsWithSupervisorsAreConducted === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseKkkfUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherSupportPlanIsCreatedInUnderstandableLanguages &&
          rv.companyApplication.whetherSupportPlanIsCreatedInUnderstandableLanguages === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseKkksUmFlg"]:
          rv.employeeApplication?.employeeInfoTwoResidenceStatusId?.toString() === RESIDENCE_STATUS.SPECIFIED_SKILL_NO2.toString()
            ? ""
            : rv.companyApplication?.whetherSpecificIndustryRequirementsAreIncludedInSupportPlan === YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.companyApplication?.whetherSpecificIndustryRequirementsAreIncludedInSupportPlan === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES
                ? "1"
                : "0",
        ["radIggseZrseUmFlg"]:
          rv.companyApplication &&
          rv.companyApplication.whetherSupportIsAppropriateForForeignersProperStay &&
          rv.companyApplication.whetherSupportIsAppropriateForForeignersProperStay === YES_NO_ANSWER.YES
            ? "1"
            : "0",
        ["radIggseTkyjjkkjkjnUmFlg"]:
          rv.employeeApplication?.employeeInfoTwoResidenceStatusId?.toString() === RESIDENCE_STATUS.SPECIFIED_SKILL_NO2.toString()
            ? ""
            : rv.companyApplication?.whetherSupportPlanMeetsIndustryStandards === YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE
              ? ""
              : rv.companyApplication?.whetherSupportPlanMeetsIndustryStandards === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES
                ? "1"
                : "0",
        ["txtSeknName"]: "",
        ["txtSeknHujnNo"]: "",
        ["hdnSearchSeknAddressCode"]: "",
        ["txtDetailSeknAddress"]: "",
        ["txtSeknTel"]: "",
        ["txtSeknDhsName"]: "",
        ["txtEntrySenTurkNo"]: "",
        ["selSeknEntYmdYear(4),selSeknEntYmdMonth(2),selSeknEntYmdDay(2)"]: "",
        ["txtEntrySenJgyName"]: "",
        ["hdnSearchEntrySenSekninAddressCode"]: "",
        ["txtDetailEntrySenSekninAddress"]: "",
        ["txtEntrySenSekninNameKnj"]: "",
        ["txtEntrySenTantoNameKnj"]: "",
        ["txtEntrySenAvailLangFr"]: "",
        ["WCCDS130Dto:selSkllJryutiCode1"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivitySkillExamOneId
            ? rv.employeeApplication.relatedActivitySkillExamLocationOne &&
              rv.employeeApplication.relatedActivitySkillExamLocationOne === EMPLOYEE_CAREER_LOCATION.JAPAN
              ? "900"
              : rv.employeeApplication && rv.employeeApplication.relatedActivitySkillExamOverseaOneNationality
                ? rv.employeeApplication.relatedActivitySkillExamOverseaOneNationality.nationalRenewVisaCsvCode
                : ""
            : "",
        ["WCCDS130Dto:selSkllJryutiCode2"]: "",
        ["WCCDS130Dto:selJpnNrkJryutiCode1"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityJapaneseExamTwoId
            ? rv.employeeApplication.relatedActivityJapaneseExamLocationTwo &&
              rv.employeeApplication.relatedActivityJapaneseExamLocationTwo === EMPLOYEE_CAREER_LOCATION.JAPAN
              ? "900"
              : rv.employeeApplication && rv.employeeApplication.relatedActivityJapaneseExamOverseaTwoNationality
                ? rv.employeeApplication.relatedActivityJapaneseExamOverseaTwoNationality.nationalRenewVisaCsvCode
                : ""
            : "",
        ["WCCDS130Dto:selJpnNrkJryutiCode2"]:
          rv.employeeApplication && rv.employeeApplication.relatedActivityJapaneseExamThreeId
            ? rv.employeeApplication.relatedActivityJapaneseExamLocationThree &&
              rv.employeeApplication.relatedActivityJapaneseExamLocationThree === EMPLOYEE_CAREER_LOCATION.JAPAN
              ? "900"
              : rv.employeeApplication && rv.employeeApplication.relatedActivityJapaneseExamOverseaThreeNationality
                ? rv.employeeApplication.relatedActivityJapaneseExamOverseaThreeNationality.nationalRenewVisaCsvCode
                : ""
            : "",
        ["WCCDS130Dto:txtKykykRdTimeMonthAvg"]:
          rv.employeeApplication && rv.employeeApplication.contractMonthlyWorkingHours
            ? Math.floor(rv.employeeApplication.contractMonthlyWorkingHours)
            : "",
        ["WCCDS130Dto:txtKykykTrmTimeKngk"]:
          rv.employeeApplication && rv.employeeApplication.contractHourlySalary ? rv.employeeApplication.contractHourlySalary : "",
        ["WCCDS130Dto:txtEntrySeknItkTsr"]: "",
        ["WCCDS130Dto:selSkllJryutiCode3"]: "",
        ["WCCDS130Dto:selJpnNrkJryutiCode3"]: "",
        ["WCCDS130Dto:txtHksKyhkntkyjgyshNo"]:
          rv.employeeApplication?.dispatchingOrganizations?.at(0)?.employmentInsuranceApplicableEstablishmentNumber ?? "",
        ["WCCDS130Dto:txtSkgsksKyhkntkyjgyshNo"]: "",
        ["WCCDS130Dto:txtTtgnszknKyhkntkyjgyshNo"]: rv.companyApplication?.businessInsuranceNumber ?? "",
        ["WCCDS130Dto:txtSeknKyhkntkyjgyshNo"]: "",
        ["WCCBS010Dto:lblFreeInputC"]: "",
      };
    });
    return csvData;
  };

  return { convertRenewVisaToCsvData };
};
