import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { Table } from "@/components/elements/Table";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { useFormTable } from "@/hooks/useFormTable";
import { dayjs } from "@/utils/dayjs";
import { YEAR_FORMAT, MONTH_FORMAT } from "@/constants/datetime";
import { MRT_ColumnDef } from "material-react-table";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TEmployeeWorkingHistoriesFormTableProps = {
  readonly: boolean;
};

export const EmployeeWorkingHistoriesFormTable: FC<TEmployeeWorkingHistoriesFormTableProps> = ({ readonly }) => {
  const methods = useFormContext<TEmployeeApplicationForm>();
  const { control, setValue } = methods;

  const columns: MRT_ColumnDef<TEmployeeApplicationForm>[] = [
    {
      header: "勤務先名称",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`workingHistories.${row.index}.jobName`}>
            <Input
              transformToFullWidth
              readonly={readonly}
              onChange={(e) => {
                if (!e.target.value) {
                  setValue(`workingHistories.${row.index}.workingStartYear`, null);
                  setValue(`workingHistories.${row.index}.workingStartMonth`, null);
                  setValue(`workingHistories.${row.index}.workingEndYear`, null);
                  setValue(`workingHistories.${row.index}.workingEndMonth`, null);
                }
              }}
            />
          </FormField>
        );
      },
    },
    {
      header: "入社年月（年）",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`workingHistories.${row.index}.workingStartYear`}>
            <InputDatePicker minDate={dayjs("1900/01/01")} maxDate={dayjs()} readonly={readonly} format={YEAR_FORMAT} views={["year"]} />
          </FormField>
        );
      },
    },
    {
      header: "入社年月（月）",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`workingHistories.${row.index}.workingStartMonth`}>
            <InputDatePicker readonly={readonly} format={MONTH_FORMAT} views={["month"]} />
          </FormField>
        );
      },
    },
    {
      header: "退社年月（年）",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`workingHistories.${row.index}.workingEndYear`}>
            <InputDatePicker minDate={dayjs("1900/01/01")} maxDate={dayjs()} readonly={readonly} format={YEAR_FORMAT} views={["year"]} />
          </FormField>
        );
      },
    },
    {
      header: "退社年月（月）",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`workingHistories.${row.index}.workingEndMonth`}>
            <InputDatePicker readonly={readonly} format={MONTH_FORMAT} views={["month"]} />
          </FormField>
        );
      },
    },
  ];

  const { table } = useFormTable({ columns, control, name: "workingHistories", readonly });

  if (table.getRowCount() >= 10) {
    table.addNewRow = undefined;
  }

  return (
    <InnerBox title="職歴（外国におけるものを含む）" bordered p={3}>
      <Table table={table} />
    </InnerBox>
  );
};
