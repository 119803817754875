import { valueOf } from "@/types/common";

export const FILE_STATUS = {
  IN_PROGRESS: 0,
  OK: 1,
  ERROR: 2,
  ERROR_MIN_SIZE: 3,
  ERROR_MAX_SIZE: 4,
  ERROR_ACCEPT_FILES: 5,
};

export type TFileStatus = valueOf<typeof FILE_STATUS>;

export const FILE_ERROR_MESSAGE = {
  [FILE_STATUS.ERROR]: "FILE is invalid",
  [FILE_STATUS.ERROR_MAX_SIZE]: "ファイルサイズは{{maxSize}}以内でお願いします。",
  [FILE_STATUS.ERROR_MIN_SIZE]: "lt {{minSize}}。",
  [FILE_STATUS.ERROR_ACCEPT_FILES]: "ファイル形式は{{extensions}}にてお願いします。",
};

export const FILE_UPLOAD_FOR = {
  INFORMATION_FILE: "INFORMATION_FILE",
  CONVERT_REQUEST_FILE: "CONVERT_REQUEST_FILE",
  PAYROLL_FILES: "PAYROLL_FILES",
  PAYCHECK_FILE: "PAYCHECK_FILE",
  COMPANY_ACCOUNT_RAS_IMMI_FILE: "COMPANY_ACCOUNT_RAS_IMMI_FILE",
  COMPANY_ACCOUNT_ACCORD_IMMI_FILE: "COMPANY_ACCOUNT_ACCORD_IMMI_FILE",
  EMPLOYEE_AVATAR_FILE: "EMPLOYEE_AVATAR_FILE",
  EMPLOYEE_AVATAR_FILE_RESIZE: "EMPLOYEE_AVATAR_FILE_RESIZE",
  EMPLOYEE_AVATAR_FILE_DPI: "EMPLOYEE_AVATAR_FILE_DPI",
  EMPLOYEE_CONTRACT_CONDITION_FILE: "EMPLOYEE_CONTRACT_CONDITION_FILE",
  EMPLOYEE_CONTRACT_EMPLOYMENT_FILE: "EMPLOYEE_CONTRACT_EMPLOYMENT_FILE",
  EMPLOYEE_PASSPORT_FILE: "EMPLOYEE_PASSPORT_FILE",
  EMPLOYEE_RESIDENCE_CARD_BACK_FILE: "EMPLOYEE_RESIDENCE_CARD_BACK_FILE",
  EMPLOYEE_RESIDENCE_CARD_FRONT_FILE: "EMPLOYEE_RESIDENCE_CARD_FRONT_FILE",
  EMPLOYEE_CAREER_TRAINING_COMPLETION_FILE: "EMPLOYEE_CAREER_TRAINING_COMPLETION_FILE",
  EMPLOYEE_CAREER_SKILL_COMPLETION_FILE: "EMPLOYEE_CAREER_SKILL_COMPLETION_FILE",
  EMPLOYEE_CAREER_RESUME_FILE: "EMPLOYEE_CAREER_RESUME_FILE",
  EMPLOYEE_CAREER_JP_COMPLETION_1_FILE: "EMPLOYEE_CAREER_JP_COMPLETION_1_FILE",
  EMPLOYEE_CAREER_JP_COMPLETION_2_FILE: "EMPLOYEE_CAREER_JP_COMPLETION_2_FILE",
  EMPLOYEE_CAREER_CONFIRM_GUIDE_FILE: "EMPLOYEE_CAREER_CONFIRM_GUIDE_FILE",
  EMPLOYEE_CAREER_CONFIRM_ORI_FILE: "EMPLOYEE_CAREER_CONFIRM_ORI_FILE",
  EMPLOYEE_CAREER_DEPEND_FAMILY_FILE: "EMPLOYEE_CAREER_DEPEND_FAMILY_FILE",
  EMPLOYEE_CAREER_DEPEND_REMIT_FILE: "EMPLOYEE_CAREER_DEPEND_REMIT_FILE",
  CONTACT_FILE: "CONTACT_FILE",
  ANY_TIME_FILES: "ANY_TIME_FILES",
  RENEW_VISA_FILES: "RENEW_VISA_FILES",
  RETIREMENT_FILE: "RETIREMENT_FILE",
};

export type TFileUploadFor = valueOf<typeof FILE_UPLOAD_FOR>;

export const MIME_TYPES = {
  IMAGE: ["image/jpg", "image/jpeg", "image/png"],
  HEIC: ["image/heic", "image/heif"],
  PDF: ["application/pdf"],
  EXCEL: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.oasis.opendocument.spreadsheet",
    "text/csv",
  ],
  WORD: [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.text",
    "application/msword",
    "application/x-ole-storage",
  ],
  TXT: ["text/plain"],
  CSV: ["text/csv"],
  XML: ["application/xml", "text/xml"],
  JPEG: ["image/jpg", "image/jpeg"],
  ZIP: ["zip", "application/octet-stream", "application/zip", "application/x-zip", "application/x-zip-compressed"],
};

export const EXTENSION_NAMES: Record<string, string> = {
  IMAGE: "Image",
  HEIC: "HEIC",
  PDF: "PDF",
  EXCEL: "Excel",
  WORD: "Word",
  TXT: "Txt",
  CSV: "CSV",
  XML: "XML",
  JPEG: "Jpeg",
  ZIP: "Zip",
};

export const EXTENSIONS = {
  IMAGE: [".jpg", ".jpeg", ".png"],
  PDF: [".pdf"],
  WORD: [".doc", ".docx"],
  ZIP: [".zip"],
  EXCEL: [".xlsx", ".csv"],
  XML: [".xml"],
  HEIC: [".heic"],
  TXT: [".txt"],
  CSV: [".csv"],
  JPEG: [".jpeg"],
};

export const FILE_EXISTED = "true";
