import { useEnum } from "@/api/services/global/enum";
import { useGetRegionList } from "@/api/services/global/master";
import { useCompanyBranchList } from "@/api/services/main/company";
import { useEmployeeList } from "@/api/services/main/employee";
import { TGetEmployeeListOutputDto } from "@/api/services/main/employee/dtos/get-employee-list.dto";
import { updateRetirementStatusApi } from "@/api/services/main/retirement-document";
import { IconSearch } from "@/assets/icons";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Avatar } from "@/components/elements/Avatar";
import { DebounceInput } from "@/components/elements/Input/Input";
import { Modal } from "@/components/elements/Modal";
import { StatusTag } from "@/components/elements/StatusTag";
import { Table } from "@/components/elements/Table";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { DATE_FORMAT } from "@/constants/datetime";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { useDialog } from "@/hooks/useDialog";
import { useSelectionTable } from "@/hooks/useSelectionTable";
import { TOption } from "@/types/common";
import { EMPLOYMENT_STATUS, RETIREMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { findLabelByValue } from "@/utils/object";
import { getFullName } from "@/utils/string";
import { toast } from "@/utils/toast";
import { Box, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useMemo, useState } from "react";

type TPickEmployeesModalProps = {
  isOpen: boolean;
  onEmployeeSelected: (employeeId: string) => void;
  onClose: () => void;
};

export const PickEmployeeRetirementModal: FC<TPickEmployeesModalProps> = ({ isOpen, onEmployeeSelected, onClose }) => {
  const { data: employmentStatusOptions } = useEnum("EEmploymentStatus");
  const { nationalityOptions } = useGetRegionList();
  const { data: companyBranch } = useCompanyBranchList();
  const [searchInput, setSearchInput] = useState("");
  const confirmModal = useDialog();
  const queryClient = useQueryClient();

  const { data: employeeRetirementList, isLoading } = useEmployeeList({
    filter: {
      fullName: searchInput || undefined,
      notRetirementStatus: [RETIREMENT_STATUS.PROCESSING, RETIREMENT_STATUS.RETIRED],
      withBillings: "true",
    },
  });

  const contractCompanyBranchOptions: TOption[] = useMemo(
    () => (companyBranch ? companyBranch?.data.map((item) => ({ label: item.branchName, value: item.id })) : []),
    [companyBranch],
  );

  // Filter employees who have current payment
  const employees = useMemo<TGetEmployeeListOutputDto[]>(() => {
    if (!employeeRetirementList?.data) return [];
    return employeeRetirementList.data.filter((employee) => employee.billings.some((billing) => billing.isCurrentPayment));
  }, [employeeRetirementList]);

  const columns = useMemo<MRT_ColumnDef<TGetEmployeeListOutputDto>[]>(
    () => [
      {
        header: "在籍状況",
        Cell: ({ row }) => {
          const { employmentStatus } = row.original;
          if (!employmentStatus) return EMPTY_STR.TEXT;
          const label = findLabelByValue(employmentStatusOptions, employmentStatus);
          if (!label) return EMPTY_STR.TEXT;
          return <StatusTag status={employmentStatus} variant="bold" title={label} />;
        },
        size: 80,
      },
      {
        header: "管理番号",
        Cell: ({ row }) => row.original.managementNumber || EMPTY_STR.TEXT,
        size: 80,
      },
      {
        header: "外国人名",
        Cell: ({ row }) => {
          const { firstName, lastName, middleName, avatarFilePath } = row.original;
          const fullName = getFullName({ firstName, lastName, middleName });

          return (
            <Stack direction="row" gap={1} alignItems="center">
              <Avatar filePath={avatarFilePath} width={30} height={40} />
              <Typography variant="body14">{fullName || EMPTY_STR.TEXT}</Typography>
            </Stack>
          );
        },
        size: 120,
      },
      {
        header: "呼び名",
        Cell: ({ row }) => {
          return <Typography variant="body14">{row.original.nickName || EMPTY_STR.TEXT}</Typography>;
        },
        size: 100,
      },
      {
        header: "国籍",
        Cell: ({ row }) => findLabelByValue(nationalityOptions, row.original.nationalityId) || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "勤務場所",
        Cell: ({ row }) => findLabelByValue(contractCompanyBranchOptions, row.original.contractCompanyBranchId) || EMPTY_STR.TEXT,
        size: 100,
      },
      {
        header: "入社日",
        Cell: ({ row }) => (row.original.hiringDate ? dayjs(row.original.hiringDate).format(DATE_FORMAT) : EMPTY_STR.DATE),
        size: 100,
      },
    ],
    [employmentStatusOptions, employeeRetirementList],
  );

  const { table, rowSelectionKeys, clearSelection } = useSelectionTable({
    columns,
    primaryKey: "id",
    rows: employees,
    singleSelection: true,
    muiSelectAllCheckboxProps: {
      onChange: () => {
        clearSelection();
      },
    },
  });

  const handleCheckOfferedEmployee = async () => {
    const employeeId = rowSelectionKeys[0];
    const selectedEmployee = employeeRetirementList?.data?.find((employee) => employee.id.toString() === employeeId);
    if (selectedEmployee?.employmentStatus === EMPLOYMENT_STATUS.OFFERED) {
      const agree = await confirmModal.show();
      if (!agree) return;
      handleUpdateRetirementStatus(selectedEmployee);
    } else {
      handleApplyEmployee();
    }
  };

  const handleUpdateRetirementStatus = async (selectedEmployee: TGetEmployeeListOutputDto) => {
    if (selectedEmployee && selectedEmployee.residenceStatusId) {
      const rs = await updateRetirementStatusApi(selectedEmployee.id, { retirementStatus: RETIREMENT_STATUS.RETIRED });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EMPLOYEE.FETCH_LIST] });
    }
  };

  const handleApplyEmployee = async () => {
    try {
      const employeeId = rowSelectionKeys[0];
      onEmployeeSelected(employeeId);
      onClose();
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title="対象外国人の追加"
      isOpen={isOpen}
      onClose={onClose}
      onOk={handleCheckOfferedEmployee}
      labelClose="キャンセル"
      labelOk="追加"
      disabledOk={!rowSelectionKeys.length}
    >
      <Stack py={2} gap={2}>
        <Box maxWidth={500}>
          <DebounceInput endAdornment={<IconSearch />} fullWidth nonControl value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
        </Box>
        <Table maxHeight={350} table={table} isLoading={isLoading} />
      </Stack>
      <ConfirmSaveModal open={confirmModal.open} onOk={confirmModal.confirm} onClose={confirmModal.cancel}>
        <Typography>内定者は退職できません。退職済みに変更しますか？この操作は元に戻せません。</Typography>
      </ConfirmSaveModal>
    </Modal>
  );
};
