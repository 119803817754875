import { fetchEmployeeInfoApi } from "@/api/services/main/employee";
import { IconUpload } from "@/assets/icons";
import { Avatar } from "@/components/elements/Avatar";
import { Button } from "@/components/elements/Button";
import { Copyable } from "@/components/elements/Copyable";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { MIME_TYPES } from "@/constants/file";
import { UploadAvatarFormDropZone } from "@/features/employee/components/UploadAvatarFormDropZone";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { state } from "@/theme/colors";
import { convertMbToByte } from "@/utils/file";
import { createUrlWithParams } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

export const AvatarUploadForm = () => {
  const { id } = useParams();
  const { control, setValue } = useFormContext<TEmployeeForm>();

  const avatarFile = useWatch({
    control,
    name: "avatarFile",
  });

  const handleGetLinkAvatar = async () => {
    if (!id) return "";
    const rs = await fetchEmployeeInfoApi(id);
    return createUrlWithParams(`${window.location.origin}/employee/${rs?.data.id}/upload-avatar`, {
      token: rs?.data.secretToken,
      companyId: rs?.data.companyId,
    });
  };

  return (
    <InnerBox title="本人画像縮尺ツール">
      <Stack gap={1} width={370} alignItems="center">
        <Typography>アップロードされた画像プレビュー</Typography>
        <Avatar filePath={avatarFile?.filePath} width={300} height={400} onRemove={() => setValue("avatarFile", null)} />
        <FormField control={control} name="avatarFile">
          <UploadAvatarFormDropZone
            options={{ multiple: false }}
            bordered={false}
            rules={{ acceptMimeTypes: MIME_TYPES.IMAGE, maxSize: convertMbToByte(10) }}
          >
            <Button
              variant="ghost"
              sx={{
                width: "100%",
                backgroundColor: "#3E3E3E !important",
                color: "#FFFFFF !important",
              }}
            >
              画像アップロード <IconUpload />
            </Button>
          </UploadAvatarFormDropZone>
        </FormField>
        {id && (
          <Copyable onCopyText={handleGetLinkAvatar}>
            <Typography color={state.link_1} sx={{ textDecoration: "underline", cursor: "pointer" }}>
              画像アップロードURLを外国人に共有してください
            </Typography>
          </Copyable>
        )}
      </Stack>
    </InnerBox>
  );
};
