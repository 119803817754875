import { TCreateOrUpdateEmployeeReqDto } from "@/api/services/main/employee/dtos/create-or-update-employee.dto";
import { ISO_FORMAT } from "@/constants/datetime";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { toNumber } from "@/utils/number";

export const convertEmployeeFormDataToReqDto = (data: TEmployeeForm): TCreateOrUpdateEmployeeReqDto => {
  return {
    managementNumber: data.managementNumber ?? null,
    lastName: data.lastName ?? "",
    middleName: data.middleName ?? null,
    firstName: data.firstName,
    nickName: data.nickName ?? null,
    nationalityId: toNumber(data.nationalityId),
    gender: data.gender,
    birthday: data.birthday.format(ISO_FORMAT),
    hiringDate: data.hiringDate.format(ISO_FORMAT),
    employmentStatus: data.employmentStatus,
    retirementStatus: data.retirementStatus ?? null,
    retirementDate: data.retirementDate ? data.retirementDate.format(ISO_FORMAT) : null,
    zipCode: data.zipCode ? data.zipCode : null,
    province: data.province ? data.province : null,
    municipality: data.municipality ? data.municipality : null,
    address: data.address ? data.address : null,
    telNumber: data.telNumber ? data.telNumber.toString() : "",
    email: data.email ?? "",
    referralCompanyName: data.referralCompanyName ?? null,
    registeredSupportOrganizationName: data.registeredSupportOrganizationName ?? null,
    skillOneStartDate: data.skillOneStartDate ? data.skillOneStartDate.format(ISO_FORMAT) : null,
    blankPeriodMonthly: data.blankPeriodMonthly ? toNumber(data.blankPeriodMonthly) : null,
    avatarFilePath: data.avatarFile?.filePath ?? null,
    avatarFileDPIPath: data.avatarFileDPIPath ?? null,
    passportCode: data.passportCode ? data.passportCode.toString() : null,
    passportPermanentAddress: data.passportPermanentAddress ? data.passportPermanentAddress : null,
    passportExpiryDate: data.passportExpiryDate ? data.passportExpiryDate.format(ISO_FORMAT) : null,
    passportFilePath: data.passportFile?.filePath ?? null,
    residenceCode: data.residenceCode ? data.residenceCode : null,
    residenceCardBackFilePath: data.residenceCardBackFile?.length ? data.residenceCardBackFile.flatMap((file) => file.filePath ?? []) : null,
    residenceCardFrontFilePath: data.residenceCardFrontFile?.length ? data.residenceCardFrontFile.flatMap((file) => file.filePath ?? []) : null,
    residenceExpiryDate: data.residenceExpiryDate ? data.residenceExpiryDate.format(ISO_FORMAT) : null,
    residencePeriodId: data.residencePeriodId ? toNumber(data.residencePeriodId) : null,
    residenceStatusId: toNumber(data.residenceStatusId),
    contractEmploymentFilePath: data.contractEmploymentFile?.length ? data.contractEmploymentFile.flatMap((file) => file.filePath ?? []) : null,
    contractConditionFilePath: data.contractConditionFile?.length ? data.contractConditionFile.flatMap((file) => file.filePath ?? []) : null,
    contractCompanyBranchId: toNumber(data.contractCompanyBranchId),
    contractStartDate: data.contractStartDate ? data.contractStartDate.format(ISO_FORMAT) : null,
    contractEndDate: data.contractEndDate ? data.contractEndDate.format(ISO_FORMAT) : null,
    contractMainOccupationId: toNumber(data.contractMainOccupationId),
    contractSpecificBusinessClassificationOneId: data.contractSpecificBusinessClassificationOneId
      ? toNumber(data.contractSpecificBusinessClassificationOneId)
      : null,
    contractSpecificBusinessClassificationTwoId: data.contractSpecificBusinessClassificationTwoId
      ? toNumber(data.contractSpecificBusinessClassificationTwoId)
      : null,
    contractSpecificBusinessClassificationThreeId: data.contractSpecificBusinessClassificationThreeId
      ? toNumber(data.contractSpecificBusinessClassificationThreeId)
      : null,
    contractMonthlySalary: toNumber(data.contractMonthlySalary),
    careerResumeFilePath: data.careerResumeFile?.filePath ?? null,
    careerTrainingStatus: data.careerTrainingStatus ?? null,
    careerMajorId: data.careerMajorId ? toNumber(data.careerMajorId) : null,
    careerJobTypeId: data.careerJobTypeId ? toNumber(data.careerJobTypeId) : null,
    careerCompletionProof: data.careerCompletionProof ?? null,
    careerTrainingCompletionFilePath: data.careerTrainingCompletionFile?.filePath ?? null,
    careerSkillCertificationOne: data.careerSkillCertificationOne ?? null,
    careerSkillExamOneId: data.careerSkillExamOneId ? toNumber(data.careerSkillExamOneId) : null,
    careerSkillExamLocationOne: data.careerSkillExamLocationOne ?? null,
    careerSkillExamOverseaOneNationalityId: data.careerSkillExamOverseaOneNationalityId
      ? toNumber(data.careerSkillExamOverseaOneNationalityId)
      : null,
    careerSkillOtherEvaluation: data.careerSkillOtherEvaluation ?? null,
    careerSkillCompletionFilePath: data.careerSkillCompletionFile?.filePath ?? null,
    careerJapaneseCertificationOne: data.careerJapaneseCertificationOne ?? null,
    careerJapaneseExamOneId: data.careerJapaneseExamOneId ? toNumber(data.careerJapaneseExamOneId) : null,
    careerJapaneseExamLocationOne: data.careerJapaneseExamLocationOne ?? null,
    careerJapaneseExamOverseaOneNationalityId: data.careerJapaneseExamOverseaOneNationalityId
      ? toNumber(data.careerJapaneseExamOverseaOneNationalityId)
      : null,
    careerJanpaneseCompletionOneFilePath: data.careerJanpaneseCompletionOneFile?.filePath ?? null,
    careerJapaneseCertificationTwo: data.careerJapaneseCertificationTwo ?? null,
    careerJapaneseExamTwoId: data.careerJapaneseExamTwoId ? toNumber(data.careerJapaneseExamTwoId) : null,
    careerJapaneseExamLocationTwo: data.careerJapaneseExamLocationTwo ?? null,
    careerJapaneseExamOverseaTwoNationalityId: data.careerJapaneseExamOverseaTwoNationalityId
      ? toNumber(data.careerJapaneseExamOverseaTwoNationalityId)
      : null,
    careerJanpaneseCompletionTwoFilePath: data.careerJanpaneseCompletionTwoFile?.filePath ?? null,
    careerConfirmationGuidanceFilePath: data.careerConfirmationGuidanceFile?.filePath ?? null,
    careerConfirmationOrientationFilePath: data.careerConfirmationOrientationFile?.filePath ?? null,
    careerDependentNote: data.careerDependentNote ?? null,
    careerDependentFamilyFilePath: data.careerDependentFamilyFile?.length
      ? data.careerDependentFamilyFile.flatMap((file) => file.filePath ?? [])
      : null,
    careerDependentRemittanceFilePath: data.careerDependentRemittanceFile?.length
      ? data.careerDependentRemittanceFile?.flatMap((file) => file.filePath ?? [])
      : null,
    note: data.note ?? null,
    hasRetiredBefore: data.hasRetiredBefore,
  };
};
