import { TGetProvincesAndMunicipalitiesResDto } from "@/api/services/global/constant/dtos/get-provinces-municipalities.dto";
import { zipCodeApi } from "@/utils/api";
import { convertZipCodeToNumber } from "@/utils/input";
import { convertFullWidthToHalfWidth } from "@/utils/pieces";
import { useEffect, useState } from "react";
import { FieldValues, Path, PathValue, UseFormReturn, useWatch } from "react-hook-form";

export type TUseLocationInfoProps<T extends FieldValues> = {
  names: {
    zipCode: Path<T>;
    province: Path<T>;
    municipality?: Path<T>;
    address?: Path<T>;
  };
  methods: UseFormReturn<T, object, undefined>;
};

export const useLocationInfo = <T extends FieldValues>({ names, methods }: TUseLocationInfoProps<T>) => {
  const { setValue } = methods;
  const zipCodeField = useWatch({ control: methods.control, name: names.zipCode });
  const [locationInfo, setLocationInfo] = useState<TGetProvincesAndMunicipalitiesResDto | undefined>(undefined);

  const dirtyFields = methods.formState.dirtyFields as { [key: string]: boolean };
  const dirtyZipCodeField = dirtyFields[names.zipCode];
  const [previousZipCode, setPreviousZipCode] = useState<string | undefined>();

  const fetchLocationInfo = async (zipCode: string) => {
    try {
      const rs = await zipCodeApi.get<TGetProvincesAndMunicipalitiesResDto>(`/${zipCode}.json`);
      setLocationInfo(rs.data);
      setValue(names.province, rs.data.prefecture as PathValue<T, Path<T>>);
      methods.trigger(names.province);
      if (names.municipality) {
        setValue(names.municipality, `${rs.data.city}` as PathValue<T, Path<T>>);
        methods.trigger(names.municipality);
      }
      if (names.address && dirtyZipCodeField) {
        setValue(names.address, `${rs.data?.suburb}` as PathValue<T, Path<T>>);
        methods.trigger(names.address);
      }
    } catch (err) {
      setValue(names.province, "" as PathValue<T, Path<T>>);
      methods.trigger(names.province);
      if (names.municipality) {
        setValue(names.municipality, "" as PathValue<T, Path<T>>);
        methods.trigger(names.municipality);
      }
      if (names.address) {
        setValue(names.address, "" as PathValue<T, Path<T>>);
        methods.trigger(names.address);
      }
    }
  };

  useEffect(() => {
    const zipCode = convertZipCodeToNumber(convertFullWidthToHalfWidth(zipCodeField));
    if (zipCode?.length === 7 && zipCode !== previousZipCode) {
      setPreviousZipCode(zipCode);
      fetchLocationInfo(zipCode);
    }
    if (zipCodeField?.length === 0) {
      setValue(names.province, "" as PathValue<T, Path<T>>);
      methods.trigger(names.province);
      if (names.municipality) {
        setValue(names.municipality, "" as PathValue<T, Path<T>>);
        methods.trigger(names.municipality);
      }
    }
  }, [zipCodeField]);

  return locationInfo;
};
