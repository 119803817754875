import { fetchEmployeeInfoApi } from "@/api/services/main/employee";
import { Button } from "@/components/elements/Button";
import { caculateSpecificSkillTransitionPeriod } from "@/features/employee/utils/caculateSpecificSkillTransitionPeriod";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { getFullName } from "@/utils/string";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TRefreshEmployeeInfoBtnProps = {
  setIsEditable?: (v: boolean) => void;
  handleCallback?: () => void;
};

export const RefreshEmployeeInfoBtn: FC<TRefreshEmployeeInfoBtnProps> = ({ handleCallback, setIsEditable }) => {
  const methods = useFormContext<TEmployeeApplicationForm>();
  const { control, setValue } = methods;

  const employeeInfoProvinceWatchField = useWatch({ control, name: "employeeInfoProvince" });

  const refreshEmployeeInfo = async () => {
    const employeeId = methods.getValues("employeeId");
    try {
      const rs = await fetchEmployeeInfoApi(employeeId);
      const employeeData = rs.data;
      if (!employeeData) return;

      const { period } = caculateSpecificSkillTransitionPeriod({
        skillOneStartDate: employeeData.skillOneStartDate ? dayjs(employeeData.skillOneStartDate) : null,
        blankPeriodMonthly: employeeData.blankPeriodMonthly?.toString(),
      });
      setValue("relatedActivitySpecificSkillTransitionPeriod", period);

      if (employeeInfoProvinceWatchField !== employeeData.province) {
        setValue("employeeInfoResidenceCardReceiveGovernmentCounterId", null);
      }
      setValue(
        "employeeInfoFullName",
        getFullName({ lastName: employeeData.lastName, firstName: employeeData.firstName, middleName: employeeData.middleName }) ?? null,
        {
          shouldDirty: true,
        },
      );
      setValue("employeeInfoBirthday", dayjs(employeeData.birthday), { shouldDirty: true });
      setValue("employeeInfoPassportExpiryDate", employeeData.passportExpiryDate ? dayjs(employeeData.passportExpiryDate) : null, {
        shouldDirty: true,
      });
      setValue("employeeInfoGender", employeeData.gender, { shouldDirty: true });
      setValue("employeeInfoPassportPermanentAddress", employeeData.passportPermanentAddress, { shouldDirty: true });
      setValue("employeeInfoProvince", employeeData.province, { shouldDirty: true });
      setValue("employeeInfoMunicipality", employeeData.municipality, { shouldDirty: true });
      setValue("employeeInfoAddress", employeeData.address, { shouldDirty: true });
      setValue(
        "employeeInfoTelNumber",
        employeeData?.telNumber || employeeData?.contractCompanyBranch?.telNumber || employeeData?.company?.telNumber || "",
        { shouldDirty: true },
      );
      setValue("employeeInfoEmail", employeeData.email, { shouldDirty: true });
      setValue("employeeInfoPassportCode", employeeData.passportCode, { shouldDirty: true });
      setValue("employeeInfoNationalityId", employeeData.nationalityId ? employeeData.nationalityId.toString() : null, {
        shouldDirty: true,
      });
      setValue("employeeInfoTwoResidenceStatusId", employeeData.residenceStatusId ? employeeData.residenceStatusId.toString() : null, {
        shouldDirty: true,
      });
      setValue("employeeInfoTwoResidencePeriodId", employeeData.residencePeriodId ? employeeData.residencePeriodId.toString() : null, {
        shouldDirty: true,
      });
      setValue("employeeInfoTwoResidenceExpiryDate", employeeData.residenceExpiryDate ? dayjs(employeeData.residenceExpiryDate) : null, {
        shouldDirty: true,
      });
      setValue("employeeInfoTwoResidenceCode", employeeData.residenceCode, { shouldDirty: true });
      setValue("relatedActivityCompletionProof", employeeData.careerCompletionProof, { shouldDirty: true });
      setValue("employeeInfoTwoRasImmiFullName", employeeData?.company?.governmentOnlineSystemAccount?.rasImmiFullName ?? "", {
        shouldDirty: true,
      });
      setValue("relatedActivityMajorId", employeeData.careerMajorId ? employeeData.careerMajorId.toString() : null, {
        shouldDirty: true,
      });
      setValue("relatedActivityJobTypeId", employeeData.careerJobTypeId ? employeeData.careerJobTypeId.toString() : null, {
        shouldDirty: true,
      });
      setValue("relatedActivitySkillCertificationOne", employeeData.careerSkillCertificationOne, {
        shouldDirty: true,
      });
      setValue("relatedActivitySkillExamOneId", employeeData.careerSkillExamOneId ? employeeData.careerSkillExamOneId.toString() : null, {
        shouldDirty: true,
      });
      setValue(
        "relatedActivitySkillExamOverseaOneNationalityId",
        employeeData.careerSkillExamOverseaOneNationalityId ? employeeData.careerSkillExamOverseaOneNationalityId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue("relatedActivitySkillExamLocationOne", employeeData.careerSkillExamLocationOne, { shouldDirty: true });
      setValue("relatedActivitySkillOtherEvaluation", employeeData.careerSkillOtherEvaluation, { shouldDirty: true });
      setValue("relatedActivityJapaneseCertificationTwo", employeeData.careerJapaneseCertificationOne, { shouldDirty: true });
      setValue("relatedActivityJapaneseExamTwoId", employeeData.careerJapaneseExamOneId ? employeeData.careerJapaneseExamOneId.toString() : null, {
        shouldDirty: true,
      });
      setValue("relatedActivityJapaneseExamLocationTwo", employeeData.careerJapaneseExamLocationOne, { shouldDirty: true });
      setValue(
        "relatedActivityJapaneseExamOverseaTwoNationalityId",
        employeeData.careerJapaneseExamOverseaOneNationalityId ? employeeData.careerJapaneseExamOverseaOneNationalityId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue("relatedActivityJapaneseCertificationThree", employeeData.careerJapaneseCertificationTwo, { shouldDirty: true });
      setValue("relatedActivityJapaneseExamThreeId", employeeData.careerJapaneseExamTwoId ? employeeData.careerJapaneseExamTwoId.toString() : null, {
        shouldDirty: true,
      });
      setValue(
        "relatedActivityJapaneseExamOverseaThreeNationalityId",
        employeeData.careerJapaneseExamOverseaTwoNationalityId ? employeeData.careerJapaneseExamOverseaTwoNationalityId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue("relatedActivityJapaneseExamLocationThree", employeeData.careerJapaneseExamLocationTwo, { shouldDirty: true });
      setValue("contractStartDate", employeeData.contractStartDate ? dayjs(employeeData.contractStartDate) : null, {
        shouldDirty: true,
      });
      setValue("contractEndDate", employeeData.contractEndDate ? dayjs(employeeData.contractEndDate) : null, {
        shouldDirty: true,
      });
      setValue(
        "contractSpecificIndustryId",
        employeeData.contractSpecificBusinessClassificationOne
          ? employeeData.contractSpecificBusinessClassificationOne.specificIndustryId.toString()
          : null,
        {
          shouldDirty: true,
        },
      );
      setValue(
        "contractSpecificBusinessClassificationId",
        employeeData.contractSpecificBusinessClassificationOneId ? employeeData.contractSpecificBusinessClassificationOneId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue(
        "contractSpecificIndustryTwoId",
        employeeData.contractSpecificBusinessClassificationTwo
          ? employeeData.contractSpecificBusinessClassificationTwo.specificIndustryId.toString()
          : null,
        {
          shouldDirty: true,
        },
      );
      setValue(
        "contractSpecificBusinessClassificationTwoId",
        employeeData.contractSpecificBusinessClassificationTwoId ? employeeData.contractSpecificBusinessClassificationTwoId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue(
        "contractSpecificIndustryThreeId",
        employeeData.contractSpecificBusinessClassificationThree
          ? employeeData.contractSpecificBusinessClassificationThree.specificIndustryId.toString()
          : null,
        {
          shouldDirty: true,
        },
      );
      setValue(
        "contractSpecificBusinessClassificationThreeId",
        employeeData.contractSpecificBusinessClassificationThreeId ? employeeData.contractSpecificBusinessClassificationThreeId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue("contractMainOccupationId", employeeData.contractMainOccupationId ? employeeData.contractMainOccupationId.toString() : null, {
        shouldDirty: true,
      });
      setValue("contractMonthlySalary", employeeData.contractMonthlySalary, { shouldDirty: true });
      setValue(
        "employeeInfoCareer",
        employeeData
          ? employeeData.contractSpecificBusinessClassificationOne?.specificIndustry?.companySpecificIndustry?.companySpecificIndustry?.toString() ??
              ""
          : null,
        {
          shouldDirty: true,
        },
      );

      if (!!setIsEditable) {
        setIsEditable(true);
      }

      if (!!handleCallback) {
        handleCallback();
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Button size="sm" onClick={() => refreshEmployeeInfo()}>
      外国人情報を再取得する
    </Button>
  );
};
