import { useGovernmentOnlineSystemAccountInfo } from "@/api/services/main/company";
import { Button } from "@/components/elements/Button";
import { FileItem } from "@/components/elements/FileItem";
import { InnerBox } from "@/components/elements/InnerBox";
import { TypoLink } from "@/components/elements/TypoLink";
import { EMPTY_STR } from "@/constants/string";
import { InfoRow } from "@/features/company/components/InfoRow";
import { AppRoutes } from "@/routes/config";
import { text } from "@/theme/colors";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CompanyAccountPage = () => {
  const { data, isLoading } = useGovernmentOnlineSystemAccountInfo();
  const governmentOnlineSystemAccountData = data?.data;
  const navigate = useNavigate();

  if (isLoading) return null;
  return (
    <Box>
      <Stack gap={2}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="head20Semi" fontWeight={100} color={text.primary}>
            オンライン申請のアカウント管理
          </Typography>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              navigate(AppRoutes.companyAccountEdit);
            }}
          >
            編集
          </Button>
        </Stack>
        <InnerBox padding="32px">
          <Stack gap={3}>
            <Stack gap={3} paddingBottom={2}>
              <Stack gap={2}>
                <Typography variant="body14Bold">出入国在留管理庁電子届出システムの利用者情報</Typography>
                <Stack>
                  <Typography variant="body14">
                    出入国在留管理庁の電子届出システムに利用者登録した際のIDとパスワードです。これらは電子届出の提出に必須の項目です。
                  </Typography>
                  <Stack flexDirection="row" alignItems="center">
                    <TypoLink variant="link14Semi" target="blank" to={"https://www.accord-immi.moj.go.jp/accord-u/profile/userLogin"}>
                      電子届出システムへのログインはこちら
                    </TypoLink>
                  </Stack>
                </Stack>
                <Stack flexDirection="row" alignItems="center" gap={5}>
                  <InfoRow
                    paddingY={1}
                    paddingX="12px"
                    padding={0}
                    gap={1}
                    titleBold={false}
                    title="認証ID"
                    contentText={governmentOnlineSystemAccountData?.accordImmiId || EMPTY_STR.TEXT}
                  />
                  <InfoRow
                    width={280}
                    paddingY={1}
                    paddingX="12px"
                    padding={0}
                    gap={1}
                    titleBold={false}
                    title="パスワード"
                    contentText={governmentOnlineSystemAccountData?.accordImmiPassword ? "設定済み" : "未設定"}
                  />
                </Stack>
                <Stack gap={1}>
                  <Typography variant="body14">出入国在留管理庁電子届出システム</Typography>
                  <Stack maxWidth="20%" gap={1}>
                    <Button size="md" disabled fullWidth>
                      アップロード
                    </Button>
                    {data?.data?.accordImmiFilePath && <FileItem filePath={data.data.accordImmiFilePath} downloadable />}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack gap={3} paddingBottom={2} borderBottom="1px solid #E5E5E5">
              <Stack gap={2}>
                <Typography variant="body14Bold">在留申請オンラインシステムの利用者情報</Typography>
                <Stack>
                  <Typography variant="body14">
                    出入国在留管理庁の在留申請オンラインシステムに利用者登録した際の登録情報です。備忘録としてご利用ください。
                  </Typography>
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <TypoLink variant="link14Semi" target="blank" to={"https://www.ras-immi.moj.go.jp/WC01/WCAAS010/"}>
                      在留申請オンラインシステムへのログインはこちら
                    </TypoLink>
                    <TypoLink variant="link14Semi" target="blank" to={"https://support.iro-hana.com/fc88d3b289dc45178b38cf6141e558b4?pvs=4"}>
                      利用登録をされる方はこちら
                    </TypoLink>
                  </Stack>
                </Stack>
                <Stack gap={2}>
                  <InfoRow
                    paddingY={1}
                    paddingX="12px"
                    padding={0}
                    gap={1}
                    titleBold={false}
                    title="申請取次者名"
                    contentText={governmentOnlineSystemAccountData?.rasImmiFullName || EMPTY_STR.TEXT}
                  />
                  <InfoRow
                    paddingY={1}
                    paddingX="12px"
                    padding={0}
                    gap={1}
                    titleBold={false}
                    title="認証ID"
                    contentText={governmentOnlineSystemAccountData?.rasImmiId || EMPTY_STR.TEXT}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </InnerBox>
      </Stack>
    </Box>
  );
};
