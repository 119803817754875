import { RegisterForm } from "@/features/auth/components/RegisterForm";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TRegisterPageProps = {};

export const RegisterPage: FC<TRegisterPageProps> = () => {
  return (
    <Stack gap={12} alignItems="center">
      <Typography variant="head24Semi">新規登録</Typography>
      <RegisterForm />
    </Stack>
  );
};
