import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { StepCard } from "@/components/elements/StepCard";
import { FormApplicationStep } from "@/features/renew-visa/components/applicationStep/formApplication/FormApplicationStep";
import { UploadApplicationStep } from "@/features/renew-visa/components/applicationStep/uploadApplication/UploadApplicationStep";
import { UploadAvatarStep } from "@/features/renew-visa/components/applicationStep/UploadAvatar/UploadAvatarStep";
import { RenewVisaDownloadFileZipBtn } from "@/features/renew-visa/components/RenewVisaDownloadFileZipBtn";
import { Typography } from "@mui/material";
import { FC } from "react";

export type TRenewVisaApplicationStepProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
  readonly: boolean;
  employeeId: number;
  basicInfoStepData: TEmployeeRenewVisaDocumentStep;
};

export const RenewVisaApplicationStep: FC<TRenewVisaApplicationStepProps> = ({ stepData, readonly, basicInfoStepData, employeeId }) => {
  return (
    <StepCard
      title={`ステップ${stepData.masterDocumentStep.stepOrder}`}
      subTitle={stepData.masterDocumentStep.stepName}
      buttons={<RenewVisaDownloadFileZipBtn readonly={readonly} stepData={stepData} />}
    >
      <Typography component="p" variant="body14" mb="16px">
        添付書類は取得に時間がかかる場合があります。余裕を持って手続きを進めましょう。
      </Typography>
      <FormApplicationStep stepData={stepData} />
      <UploadApplicationStep stepData={stepData} basicInfoStepData={basicInfoStepData} />
      <UploadAvatarStep readonly={readonly} employeeId={employeeId} stepData={stepData} />
    </StepCard>
  );
};
