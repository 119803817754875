import { IconCheck } from "@/assets/icons";
import { FormField } from "@/components/elements/FormField";
import { FileBoxInput } from "@/features/renew-visa/components/applicationStep/uploadApplication/FileBoxInput";
import { TRenewVisaUploadFileForm } from "@/features/renew-visa/types/renewVisaUploadFileForm";
import { background, divider, state, text } from "@/theme/colors";
import { css, Stack, styled, Typography } from "@mui/material";
import { FC } from "react";
import { Control } from "react-hook-form";

type TGroupSixFileBoxInputProps = {
  title: string;
  control: Control<TRenewVisaUploadFileForm>;
  name: keyof TRenewVisaUploadFileForm;
  filePath?: string;
  readonly?: boolean;
  basicInformationConditions?: string[];
  required?: boolean;
};

export const GroupSixFileBoxInput: FC<TGroupSixFileBoxInputProps> = ({
  control,
  title,
  name,
  readonly,
  filePath,
  basicInformationConditions,
  required,
}) => {
  let isUploaded = !!filePath;
  let isShowFileInput = false;
  // if table 2_3 or 2_3 and do not select A-E and file path is "true" then file has been not uploaded yet
  if (
    basicInformationConditions?.length === 0 &&
    (name === "residenceApplicationsForEmployeeTable2And2FilePath" || name === "residenceApplicationsForEmployeeTable2And3FilePath")
  ) {
    isUploaded = filePath ? filePath !== "true" : false;
    isShowFileInput = true;
  }
  return (
    <Stack flexDirection="row" alignItems="center" gap={2} padding={2} sx={{ border: "1px solid #E6E7E8", borderRadius: "8px" }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        width={16}
        height={16}
        borderRadius={16}
        bgcolor={isUploaded ? state.success_1 : divider.middle}
      >
        <IconCheck color={background.white} />
      </Stack>
      <Stack direction="row" width={820}>
        <TitleWrapper variant="sub16Semi">{title}</TitleWrapper>
        {required && (
          <Typography ml={0.5} color={text.error}>
            *
          </Typography>
        )}
      </Stack>
      {isShowFileInput === true ? (
        <FormField sx={{ width: "192px" }} name={name} control={control} errorTextAlign="right">
          <FileBoxInput readonly={readonly} multiple={false} />
        </FormField>
      ) : (
        <></>
      )}
    </Stack>
  );
};

const options = { shouldForwardProp: (propName: string) => !["downloadable"].includes(propName) };

const FileItemDownload = css`
  cursor: pointer;
  color: ${state.link_1};
  :hover {
    color: ${state.link_2};
  }
`;

const TitleWrapper = styled(Typography, options)<{ downloadable?: boolean | null }>`
  ${({ downloadable }) => downloadable && FileItemDownload}
`;
