import { TEmployeeRetirementDocumentStep } from "@/api/entities/employee-retirement-document-step.entity";
import { finishSubmitDocumentStepApi } from "@/api/services/main/retirement-document";
import { TEmployeeRetirementDocumentOutputDto } from "@/api/services/main/retirement-document/dtos/get-employee-retirement-document.dto";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Button } from "@/components/elements/Button";
import { TypoLink } from "@/components/elements/TypoLink";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { RetirementStepCard } from "@/features/retirement/components/retirement-document/documents/RetirementStepCard";
import { useDialog } from "@/hooks/useDialog";
import { DOCUMENT_STEP_KEYS, EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS } from "@/types/enum";
import { showError } from "@/utils/error";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type TStepProps = {
  document: TEmployeeRetirementDocumentOutputDto;
  stepData: TEmployeeRetirementDocumentStep;
  readonly?: boolean;
};

export const AcceptingWorkerSubmitStep: FC<TStepProps> = ({ document, stepData, readonly }) => {
  const { masterDocumentStep } = stepData;
  const confirmFinish = useDialog();
  const queryClient = useQueryClient();
  const { stepOrder, stepName } = masterDocumentStep;
  const { documentGroupId } = useParams();
  const [completeOtherSteps, setCompleteOtherSteps] = useState<boolean>(false);

  useEffect(() => {
    const createConvertDocumentStep = document.steps.find(
      (step) => step.masterDocumentStep.stepKey === DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_CONVERT_REQUEST,
    );
    const hasCompleteConvertRequest = !!createConvertDocumentStep?.convertRequests?.find(
      (req) => req.status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE,
    );
    const hasDownloadConvertRequest = !!createConvertDocumentStep?.convertRequests?.find((req) => req.isDownloaded === true);
    setCompleteOtherSteps(hasCompleteConvertRequest && hasDownloadConvertRequest);
  }, [document]);

  const handleFinishSubmitDocument = async () => {
    try {
      const agree = await confirmFinish.show();
      if (!agree) return;
      window.open("https://www.accord-immi.moj.go.jp/accord-u/profile/userLogin");
      await finishSubmitDocumentStepApi(stepData.id);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_DOCUMENT, documentGroupId] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <RetirementStepCard
      title={`ステップ${stepOrder}`}
      subTitle={stepName}
      buttons={
        <Button variant="primary" size="sm" disabled={!completeOtherSteps || readonly} onClick={handleFinishSubmitDocument}>
          提出
        </Button>
      }
    >
      <Stack spacing={1}>
        <Typography variant="body14Bold">
          電子システムで提出後、irohanaで届出完了ボタンを押すとステップが完了となります。ステップ完了すると以降編集ができなくなります。
        </Typography>
        <Typography variant="body14">電子申請システムで届出や添付書類を申請してください。</Typography>
        <TypoLink to="https://support.iro-hana.com/6993ec2fd8154ad5b89eea64f9f7e1aa?pvs=4" target="_blank" variant="link14Semi">
          申請フローの詳細はこちら (notion)
        </TypoLink>
      </Stack>
      <ConfirmSaveModal open={confirmFinish.open} onOk={confirmFinish.confirm} onClose={confirmFinish.cancel}>
        <Typography>
          提出形式に変換したファイルを用いて届出の提出に移ります。出入国在留管理庁の電子届出システムが開かれます、よろしいですか？
        </Typography>
      </ConfirmSaveModal>
    </RetirementStepCard>
  );
};
