import { uploadFileApi } from "@/api/services/global/file";
import { Button } from "@/components/elements/Button";
import { FileItem } from "@/components/elements/FileItem";
import { useFormField } from "@/components/elements/FormField";
import { FILE_UPLOAD_FOR, MIME_TYPES } from "@/constants/file";
import { state } from "@/theme/colors";
import { showError } from "@/utils/error";
import { makeUuid } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { Stack, styled } from "@mui/material";
import { FC } from "react";

type TFileBoxInputProps = {
  readonly?: boolean;
  multiple?: boolean;
  maxFiles?: number;
};

export const FileBoxInput: FC<TFileBoxInputProps> = ({ readonly, multiple = false, maxFiles }) => {
  const { field, fieldState } = useFormField();

  const handleUploadFile = async (files: File[]) => {
    try {
      if (multiple) {
        if (maxFiles && files.length + (field?.value?.length ?? 0) > maxFiles) {
          toast.error(`${maxFiles}ファイル以下を選択してください。`);
          return;
        }
        const arrfilePath = await Promise.all(
          files.map(async (file) => {
            const rs = await uploadFileApi({ file, uploadFor: FILE_UPLOAD_FOR.RENEW_VISA_FILES });
            return rs.data.filePath;
          }),
        );
        field?.onChange([...(field?.value ?? []), ...arrfilePath]);
      } else {
        const file = files[0];
        const rs = await uploadFileApi({ file, uploadFor: FILE_UPLOAD_FOR.RENEW_VISA_FILES });
        field?.onChange(rs.data.filePath);
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      {multiple ? (
        <Stack alignItems="flex-end" gap={1}>
          <Button
            name={field?.name}
            component="label"
            variant="outline"
            disabled={readonly}
            sx={{
              minWidth: "192px",
              paddingY: "25px !important",
              borderColor: fieldState?.error ? `${state.error_1}!important` : undefined,
              color: fieldState?.error ? `${state.error_1}!important` : undefined,
            }}
          >
            ファイルを選択する
            <VisuallyHiddenInput
              type="file"
              accept={MIME_TYPES.PDF.join("")}
              onChange={(event) => event.target.files && handleUploadFile(Array.from(event.target.files))}
              onClick={(event) => {
                event.currentTarget.value = "";
              }}
              multiple={multiple}
            />
          </Button>
          <Stack>
            {field?.value &&
              field.value.length > 0 &&
              field.value
                .filter((filePath: string) => filePath !== "true") // filePath !== "true" is for the case of the file path is real path
                .map((filePath: string) => (
                  <FileItemWrap
                    key={makeUuid()}
                    downloadable
                    enableFileSize
                    filePath={filePath}
                    onRemove={!readonly ? () => field.onChange(field.value.filter((item: string) => item !== filePath)) : undefined}
                  />
                ))}
          </Stack>
        </Stack>
      ) : (
        <>
          {field?.value && field.value !== "true" ? ( // field.value !== "true" is for the case of the file is is real path
            <FileItemWrap downloadable enableFileSize filePath={field.value} onRemove={!readonly ? () => field.onChange(null) : undefined} />
          ) : (
            <Button
              name={field?.name}
              component="label"
              variant="outline"
              disabled={readonly}
              sx={{
                minWidth: "192px",
                paddingY: "25px !important",
                borderColor: fieldState?.error ? `${state.error_1}!important` : undefined,
                color: fieldState?.error ? `${state.error_1}!important` : undefined,
              }}
            >
              ファイルを選択する
              <VisuallyHiddenInput
                type="file"
                accept={MIME_TYPES.PDF.join("")}
                onChange={(event) => event.target.files && handleUploadFile(Array.from(event.target.files))}
                onClick={(event) => {
                  event.currentTarget.value = "";
                }}
                multiple={multiple}
              />
            </Button>
          )}
        </>
      )}
    </>
  );
};

const FileItemWrap = styled(FileItem)`
  .MuiTypography-body1 {
    margin-right: 10px;
  }
`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
