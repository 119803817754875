import { Modal } from "@/components/elements/Modal";
import { FCC } from "@/types/common";
import { Box } from "@mui/material";

type ConfirmReloadModalProps = {
  title?: string;
  open: boolean;
  onOk: () => void;
  enableFooter?: boolean;
};

export const ConfirmReloadModal: FCC<ConfirmReloadModalProps> = ({ open, onOk, title, enableFooter, children }) => {
  return (
    <Modal
      isOpen={open}
      onOk={onOk}
      labelOk={"リロードする"}
      width={500}
      title={title}
      enableFooter={enableFooter}
      enableCloseIcon={false}
      hiddenCloseBtn={true}
    >
      <Box mb={2}>{children}</Box>
    </Modal>
  );
};
