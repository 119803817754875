import { uploadFileApi } from "@/api/services/global/file";
import { Button } from "@/components/elements/Button";
import { useFormField } from "@/components/elements/FormField";
import { FILE_STATUS, FILE_UPLOAD_FOR } from "@/constants/file";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { button, divider, text } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { FCC } from "@/types/common";
import { TExtendFile, TExtendFileOption } from "@/types/file";
import { showError } from "@/utils/error";
import { TFileRules, convertByteToMb, covertMimeTypesToAccepts, getFileStatusWithRules } from "@/utils/file";
import { makeUuid } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { Box, Stack, Typography, css, styled } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { useFormContext, useWatch } from "react-hook-form";

export type TUploadAvatarFormDropZoneProps = {
  value?: TExtendFile | TExtendFile[] | null;
  onChange?: (value: TExtendFile | TExtendFile[] | null) => void;
  options?: Omit<DropzoneOptions, "onDrop">;
  nonControl?: boolean;
  rules?: TFileRules;
  bordered?: boolean;
  disabled?: boolean;
  hiddenCloseIcon?: boolean;
  hideAfterUpload?: boolean;
  handleFileRejections?: (fileRejections: FileRejection[]) => void;
};

export const UploadAvatarFormDropZone: FCC<TUploadAvatarFormDropZoneProps> = ({
  options = {},
  value,
  onChange,
  children,
  rules = {},
  nonControl = false,
  bordered = true,
  disabled,
  hideAfterUpload = false,
  handleFileRejections,
}) => {
  const { field } = useFormField(nonControl);
  const { setValue, control } = useFormContext<TEmployeeForm>();
  const [files, setFiles] = useState<TExtendFile[]>([field?.value ?? value ?? []].flat());
  const [uploading, setUploading] = useState(false);
  const [hideDropZone, setHideDropZone] = useState(false);

  const avatarFile = useWatch({
    control,
    name: "avatarFile",
  });

  const avatarFileDPIPathWatchField = useWatch({
    control,
    name: "avatarFileDPIPath",
  });

  useEffect(() => {
    if (field?.value ?? value) {
      const newValue: TExtendFile[] = [field?.value ?? value].flat();
      if (files.some((file) => !newValue.find(({ id }) => file.id === id))) {
        setFiles([field?.value ?? value].flat());
      }
    } else {
      setFiles([]);
    }

    if (!avatarFile) {
      setValue("avatarFileDPIPath", null);
    }
  }, [value, field]);

  useEffect(() => {
    if (hideAfterUpload && files.length > 0) {
      setHideDropZone(true);
      return;
    }

    setHideDropZone(false);
  }, [files]);

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (rules?.maxSize && acceptedFiles[0]?.size > rules?.maxSize) {
        toast.error(`ファイルサイズは${convertByteToMb(rules.maxSize)}MB以内でお願いします。`);
        return;
      }

      if (rules?.maxFile && acceptedFiles.length + files.length > rules?.maxFile) {
        toast.error(`ファイルサイズは${rules?.maxFile}枚までアップロード可能です。`);
        return;
      }
      if (fileRejections.length > 0 && handleFileRejections) {
        handleFileRejections(fileRejections);
        return;
      }
      let newFiles = files;
      setUploading(true);
      const droppedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          const fileOption: TExtendFileOption = {
            id: makeUuid(),
            status: getFileStatusWithRules(file, rules),
          };

          if (fileOption.status === FILE_STATUS.OK) {
            try {
              const avatarFilePath = await uploadFileApi({ file, uploadFor: FILE_UPLOAD_FOR.EMPLOYEE_AVATAR_FILE });
              const avatarFileDPIPath = await uploadFileApi({ file, uploadFor: FILE_UPLOAD_FOR.EMPLOYEE_AVATAR_FILE_DPI });
              setValue("avatarFileDPIPath", avatarFileDPIPath.data.filePath);
              return Object.assign(file, { ...fileOption, filePath: avatarFilePath.data.filePath });
            } catch (error) {
              showError(error);
              return Object.assign(file, { ...fileOption, status: FILE_STATUS.ERROR });
            }
          }
          return Object.assign(file, fileOption);
        }),
      );

      if (options.multiple) {
        newFiles = [newFiles, droppedFiles].flat();
      } else {
        newFiles = [droppedFiles[0]];
      }
      if (droppedFiles.every((file) => file.status === FILE_STATUS.OK)) {
        setFiles(newFiles);
        triggerChangeFiles(newFiles);
      }
      setUploading(false);
    },
    [files],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...options,
    onDrop,
    accept: covertMimeTypesToAccepts(rules.acceptMimeTypes),
    disabled: uploading || disabled,
  });

  const triggerChangeFiles = (files: TExtendFile[]) => {
    const { multiple } = options;
    onChange?.(multiple === false ? files.at(0) ?? null : files);
    field?.onChange(multiple === false ? files.at(0) ?? null : files);
    field?.onBlur();
  };

  return (
    <Box>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        {!hideDropZone && (
          <DropZoneArea dragged={isDragActive} bordered={bordered}>
            {children ?? (
              <>
                <Typography variant="body14">アップロードするファイルをドラッグ&ドロップ</Typography>
                <Typography variant="body14">または</Typography>
                <Button variant="outline">ファイルを選択する</Button>
              </>
            )}
            {uploading && <UploadingBackup>作成中…</UploadingBackup>}
          </DropZoneArea>
        )}
      </Box>
    </Box>
  );
};

const options = { shouldForwardProp: (propName: string) => !["dragged", "bordered"].includes(propName) };

const DropZoneAreaActive = css`
  border-color: ${button.primary};
`;

const DropZoneArea = styled(Stack, options)<{ dragged: boolean; bordered: boolean }>`
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 16px;
  border: 1px dashed ${divider.middle};
  border-radius: ${rounded.sm};
  overflow: hidden;

  ${({ dragged }) => dragged && DropZoneAreaActive}
  ${({ bordered }) =>
    !bordered &&
    css`
      padding: 0;
      border: none;
    `}
`;

const UploadingBackup = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: ${button.disable};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${text.white};
`;
