import { clientApi, logout } from "@/utils/api";
import { setToken } from "@/utils/token";

import { TLoginReqDto, TLoginResDto } from "@/api/services/main/auth/dtos/login.dto";
import Cookies from "js-cookie";
import { TRequestResetPasswordReqDto, TRequestResetPasswordResDto } from "@/api/services/main/auth/dtos/request-reset-password.dto";
import { TGetMeResDto } from "@/api/services/main/auth/dtos/get-me.dto";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { TRefreshTokenReqDto, TRefreshTokenResDto } from "@/api/services/main/auth/dtos/refresh-token.dto";
import { TResetPasswordReqDto, TResetPasswordResDto } from "@/api/services/main/auth/dtos/reset-password.dto";
import { TSetPasswordReqDto, TSetPasswordResDto } from "@/api/services/main/auth/dtos/set-password.dto";

export const loginApi = async (payload: TLoginReqDto): Promise<TLoginResDto> => {
  const rs = await clientApi.post<TLoginResDto>("/login", payload);

  const { data } = rs.data;
  logout();
  setToken(data.accessToken, data.refreshToken);
  Cookies.set("email", data.email);
  Cookies.set("companyId", data.companyId.toString());
  Cookies.set("companyCode", data.companyCode.toString());
  Cookies.set("userId", data.userId.toString());
  return rs.data;
};

export const requestResetPasswordApi = async (payload: TRequestResetPasswordReqDto): Promise<TRequestResetPasswordResDto> => {
  const { data } = await clientApi.post<TRequestResetPasswordResDto>("/request-reset-password", payload);
  return data;
};

export const fetchMeApi = async (): Promise<TGetMeResDto> => {
  const { data } = await clientApi.get<TGetMeResDto>("/get-me");
  return data;
};

export const useMe = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.USER.FETCH_ME],
    queryFn: fetchMeApi,
  });
};

export const refreshTokenApi = async (payload: TRefreshTokenReqDto): Promise<TRefreshTokenResDto> => {
  const rs = await clientApi.post<TRefreshTokenResDto>("/refresh-token", payload);
  const { data } = rs.data;
  setToken(data.accessToken, data.refreshToken);
  return rs.data;
};

export const setPasswordApi = async (payload: TSetPasswordReqDto): Promise<TSetPasswordResDto> => {
  const { data } = await clientApi.post<TSetPasswordResDto>("/set-password", payload);
  return data;
};

export const resetPasswordApi = async (payload: TResetPasswordReqDto): Promise<TResetPasswordResDto> => {
  const { data } = await clientApi.post<TResetPasswordResDto>("/reset-password", payload);
  return data;
};
