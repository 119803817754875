import { useEmployeeList } from "@/api/services/main/employee";
import { TGetEmployeeListOutputDto } from "@/api/services/main/employee/dtos/get-employee-list.dto";
import { Avatar } from "@/components/elements/Avatar";
import { Table } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { TaskCard } from "@/features/dashboard/components/tasks/TaskCard";
import { EMPLOYEE_LIST_TAB } from "@/features/employee/constants/employeeListTab";
import { useTable } from "@/hooks/useTable";
import { AppRoutes } from "@/routes/config";
import { background } from "@/theme/colors";
import { rounded, spacing } from "@/theme/variables";
import { EMPLOYMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { getFullName } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { Box, Typography, styled } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const EmployeeOfferTaskCard = () => {
  const { data: employeeListData, isLoading: isEmployeeListData } = useEmployeeList({
    filter: {
      employmentStatus: [EMPLOYMENT_STATUS.OFFERED],
      page: 1,
      perPage: 4,
      sorts: ["employee.hiringDate|DESC"],
    },
  });

  const navigate = useNavigate();

  const columns = useMemo<MRT_ColumnDef<TGetEmployeeListOutputDto>[]>(
    () => [
      {
        header: "入社予定日",
        Cell: ({ row }) => (row.original.hiringDate ? dayjs(row.original.hiringDate).format(DATE_FORMAT) : EMPTY_STR.DATE),
        size: 100,
        muiTableBodyCellProps: {
          sx: { minWidth: "unset" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset" },
        },
      },
      {
        header: "在留期限",
        Cell: ({ row }) => (row.original.residenceExpiryDate ? dayjs(row.original.residenceExpiryDate).format(DATE_FORMAT) : EMPTY_STR.DATE),
        size: 100,
        muiTableBodyCellProps: {
          sx: { minWidth: "unset" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset" },
        },
      },
      {
        header: "外国人名",
        Cell: ({ row }) => {
          const fullName = getFullName({ firstName: row.original.firstName, lastName: row.original.lastName, middleName: row.original.middleName });
          return (
            <Box display="flex" alignItems="center" gap={1.75}>
              <Avatar filePath={row.original.avatarFilePath} alt="avatar" width={24} height={24} />
              <Typography variant="body14"> {fullName}</Typography>
            </Box>
          );
        },
      },
      {
        header: "国籍",
        Cell: ({ row }) => row.original.nationality?.nationalityName || EMPTY_STR.TEXT,
      },
    ],
    [],
  );

  const { table } = useTable({
    rows: employeeListData?.data ?? [],
    columns,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const dynamicRoute = getDynamicRoute(AppRoutes.employeeEdit, { id: row.original.id });
        navigate(dynamicRoute);
      },
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <TaskCard
      title="入社予定者"
      seeMoreHref={`${AppRoutes.employeeList}?tab=${EMPLOYEE_LIST_TAB.OFFER}`}
      subTitle={
        <SubTitle>
          <People variant="cap12Bold">{employeeListData?.pagination.total}人</People>
        </SubTitle>
      }
    >
      <Table table={table} isLoading={isEmployeeListData} />
    </TaskCard>
  );
};

const SubTitle = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
`;

const People = styled(Typography)`
  padding: ${spacing.xxs} ${spacing.sm};
  background-color: ${background.primary};
  border-radius: ${rounded.xs};
`;
