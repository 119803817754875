import { uploadFileApi } from "@/api/services/global/file";
import { useEmployeeList } from "@/api/services/main/employee";
import { updateMultiPayrollsApi } from "@/api/services/main/regular-document/acceptance-activity-document";
import { ConfirmLeavePage } from "@/components/commons/ConfirmLeavePage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { ISO_FORMAT } from "@/constants/datetime";
import { FILE_STATUS, FILE_UPLOAD_FOR } from "@/constants/file";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { ConfirmResetData } from "@/features/regular-document/components/accept-activity/paycheck/ConfirmResetData";
import { PAYCHECK_CHOSEN_TYPE, TPaycheckChosenType } from "@/features/regular-document/constants/paycheckChosenType";
import { PAYROLL_STATUS } from "@/features/regular-document/constants/payrollStatus";
import { TPayrollRecord } from "@/features/regular-document/types/payrollRecord";
import { TPayrollResourceData } from "@/features/regular-document/types/PayrollResourceData";
import { TPayrollTableRow } from "@/features/regular-document/types/payrollTableRow";
import { checkInValidFormatFile } from "@/features/regular-document/utils/checkInValidFormatFile";
import { convertToDataTableRow, makePayrollRequestsFromRecords, paycheckCSVConverter } from "@/features/regular-document/utils/convert";
import { mappingPayrollWithEmployees } from "@/features/regular-document/utils/mappingCSVWithData";
import { useDialog } from "@/hooks/useDialog";
import { AppRoutes } from "@/routes/config";
import { FCC } from "@/types/common";
import { EMPLOYMENT_STATUS } from "@/types/enum";
import { TExtendFile } from "@/types/file";
import { showError } from "@/utils/error";
import { convertCSVToJson } from "@/utils/file";
import { toNumber } from "@/utils/number";
import { pick } from "@/utils/object";
import { getEndDateOfQuarter, getStartDateOfQuarter, makeUuid } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { useQueryClient } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

type PayCheckType = {
  resourceData: TPayrollResourceData;
  chosenType: TPaycheckChosenType;
  paycheckRows: TPayrollTableRow[];
  paycheckData: TPayrollRecord[];
  currentFile: TExtendFile | undefined;
  isLoading: boolean;
  isDirty: boolean;
  readonly: boolean;
  isEditable: boolean;
  isInvalidData: boolean;
  isInvalidFormatFile: boolean;
  payrollFilesTotal: number;
  setIsEditable: (v: boolean) => void;
  showConfirmReset: () => Promise<boolean>;
  handleSave: () => Promise<void>;
  handleSaveDraft: () => Promise<void>;
  resetData: () => void;
  setIsDirty: (v: boolean) => void;
  handleSetCurrentFile: (value: TExtendFile) => Promise<void>;
  handleChosenType: (v: TPaycheckChosenType) => Promise<void>;
  updatePayroll: (newData: TPayrollRecord) => void;
  updateMultiPayroll: (employeeId: number, newData: Partial<TPayrollRecord>) => void;
};

const PayCheckContext = createContext<PayCheckType | undefined>(undefined);

type PayCheckProviderProps = {
  previousData?: TPayrollRecord[];
  previousFilePath?: string | null;
  resourceData: TPayrollResourceData;
  stepDocumentId: number;
  readonly?: boolean;
  isEditable?: boolean;
  payrollFilesTotal?: number;
};

export const PayCheckProvider: FCC<PayCheckProviderProps> = ({
  children,
  previousData,
  previousFilePath,
  resourceData,
  stepDocumentId,
  readonly = false,
  isEditable: editable,
  payrollFilesTotal = 0,
}) => {
  const [currentFile, setCurrentFile] = useState<TExtendFile | undefined>(
    previousFilePath
      ? ({
          id: makeUuid(),
          status: FILE_STATUS.OK,
          filePath: previousFilePath,
        } as TExtendFile)
      : undefined,
  );
  const [isInvalidFormatFile, setIsInvalidFormatFile] = useState(false);
  const [paycheckData, setPaycheckData] = useState<TPayrollRecord[]>([]);
  const [chosenType, setChosenType] = useState<TPaycheckChosenType>(
    resourceData.payrollAskIrohanaSupport
      ? PAYCHECK_CHOSEN_TYPE.ASK_IROHANA_SUPPORT
      : !previousFilePath && previousData
        ? PAYCHECK_CHOSEN_TYPE.LOAD_DATA
        : PAYCHECK_CHOSEN_TYPE.UPLOAD_FILE,
  );

  const [isLoading, setIsLoading] = useState(false);
  const isNeedMapping = useRef(false);
  const confirmReset = useDialog();
  const [isDirty, setIsDirty] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isEditable, setIsEditable] = useState(!!editable);
  const { refetch } = useEmployeeList({
    enabled: false,
    filter: {
      startWorking: getStartDateOfQuarter(pick(resourceData, "year", "quarter")).format(ISO_FORMAT),
      endWorking: getEndDateOfQuarter(pick(resourceData, "year", "quarter")).format(ISO_FORMAT),
      residenceStatusIds: [1, 2],
      employmentStatus: [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT],
    },
  });

  const mappingDataWithEmployee = async (data: TPayrollRecord[]) => {
    if (resourceData.status === "complete") {
      setPaycheckData(data);
      return;
    }
    try {
      setIsLoading(true);
      const { data: employeeList } = await refetch({});
      if (employeeList) {
        const mapping = mappingPayrollWithEmployees(data, employeeList.data, resourceData.quarter);
        setPaycheckData(mapping);
      }
    } catch (error) {
      if (typeof error === "string") {
        toast.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (previousData?.length) {
      mappingDataWithEmployee(previousData);
    }
  }, [previousData]);

  useEffect(() => {
    if (chosenType === PAYCHECK_CHOSEN_TYPE.LOAD_DATA && isDirty) {
      mappingDataWithEmployee([]);
    }
  }, [chosenType]);

  const resetData = () => {
    setIsDirty(true);
    setCurrentFile(undefined);
    setPaycheckData([]);
  };

  const handleChosenType = async (v: TPaycheckChosenType) => {
    if (paycheckData.length || currentFile) {
      const result = await confirmReset.show();
      if (result) {
        setIsDirty(true);
        setChosenType(v);
        resetData();
      }
      return;
    }
    setIsDirty(true);
    setChosenType(v);
  };

  const handleSetCurrentFile = async (value: TExtendFile) => {
    try {
      setIsDirty(true);
      setIsLoading(true);
      const rs = await uploadFileApi({ uploadFor: FILE_UPLOAD_FOR.PAYCHECK_FILE, file: value });
      setCurrentFile(Object.assign(value, { filePath: rs.data.filePath }));
      const jsonData = await convertCSVToJson(value);

      const isInvalid = checkInValidFormatFile(jsonData);
      setIsInvalidFormatFile(isInvalid);
      if (isInvalid) {
        setIsLoading(false);
        return;
      }

      const dataConvert = paycheckCSVConverter(jsonData);
      await mappingDataWithEmployee(dataConvert);
      isNeedMapping.current = true;
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePayroll = (newData: TPayrollRecord) => {
    setPaycheckData((cur) => {
      const index = cur.findIndex(({ recordId }) => newData.recordId === recordId);
      if (index === -1) return cur;
      cur[index] = newData;
      return [...cur];
    });
    setIsDirty(true);
  };

  const updateMultiPayroll = (employeeId: number, newData: Partial<TPayrollRecord>) => {
    setPaycheckData((cur) => {
      const newValue = cur.map((item) => {
        if (!item.employeeId) return item;
        if (item.employeeId === employeeId) return { ...item, ...newData };
        return item;
      });
      return newValue;
    });
    setIsDirty(true);
  };

  const handleSave = async (isDraft: boolean) => {
    if (!isEditable) {
      setIsDirty(false);
      navigate(AppRoutes.regularPresent);
      return;
    }

    try {
      const payrolls = makePayrollRequestsFromRecords(paycheckData, resourceData.year, resourceData.quarter, isDraft);
      const rs = await updateMultiPayrollsApi(stepDocumentId, {
        payrollAskIrohanaSupport: chosenType === PAYCHECK_CHOSEN_TYPE.ASK_IROHANA_SUPPORT,
        payrollFilePath: currentFile?.filePath ?? null,
        payrolls,
      });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_EMPLOYEE_PAYROLL_LIST] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_ACCEPTANCE_ACTIVITY_DOCUMENT] });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      setIsDirty(false);
      navigate(AppRoutes.regularPresent);
    } catch (error) {
      showError(error);
    }
  };

  const paycheckRows = useMemo(
    () =>
      convertToDataTableRow(paycheckData).sort((a, b) => {
        if (toNumber(a.no) > toNumber(b.no)) return 1;
        if (toNumber(a.no) < toNumber(b.no)) return -1;
        return 0;
      }),
    [paycheckData],
  );

  const isInvalidData = useMemo(() => {
    return paycheckData.some(({ status }) => status === PAYROLL_STATUS.ERROR);
  }, [paycheckData]);

  return (
    <PayCheckContext.Provider
      value={{
        readonly,
        chosenType,
        paycheckRows,
        paycheckData,
        currentFile,
        isLoading,
        isDirty,
        resourceData,
        isEditable,
        isInvalidData,
        payrollFilesTotal,
        isInvalidFormatFile,
        setIsDirty,
        setIsEditable,
        showConfirmReset: confirmReset.show,
        updatePayroll,
        updateMultiPayroll,
        resetData,
        handleSave: () => handleSave(false),
        handleSaveDraft: () => handleSave(true),
        handleSetCurrentFile,
        handleChosenType,
      }}
    >
      {children}
      <ConfirmLeavePage shouldBlock={isDirty} />
      <ConfirmResetData open={confirmReset.open} onClose={confirmReset.cancel} onOk={confirmReset.confirm} />
    </PayCheckContext.Provider>
  );
};

export const usePayCheck = (): PayCheckType => {
  const context = useContext(PayCheckContext);
  if (!context) {
    throw new Error("usePayCheck must be used within a PayCheckProvider");
  }
  return context;
};
