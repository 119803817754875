import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { compressPdfApi, fetchFileInfoApi, fetchSignedUrlApi } from "@/api/services/global/file";
import {
  fetchRenewVisaDocumentInfoApi,
  fetchRenewVisaDocumentListToExportCsvApi,
  fetchRenewVisaUploadAvatarFileDocumentApi,
  updateRenewVisaDocumentMergedPdfFilePathApi,
} from "@/api/services/main/renew-visa";
import { Button } from "@/components/elements/Button";
import { useConvertRenewVisaToCsvData } from "@/features/renew-visa/hook/useConvertRenewVisaToCsvData";
import { useMergeRenewVisaFile } from "@/features/renew-visa/hook/useMergeRenewVisaFile";
import { DOCUMENT_STATUS } from "@/types/enum";
import { showError } from "@/utils/error";
import { generateFileFromFilePath } from "@/utils/export";
import { getFullName } from "@/utils/string";
import axios from "axios";
import iconv from "iconv-lite";
import { json2csv } from "json-2-csv";
import JSZip from "jszip";
import { FC, useState } from "react";

type TRenewVisaDownloadFileZipBtnProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
  readonly: boolean;
};

export const RenewVisaDownloadFileZipBtn: FC<TRenewVisaDownloadFileZipBtnProps> = ({ stepData, readonly }) => {
  const { convertRenewVisaToCsvData } = useConvertRenewVisaToCsvData();

  const [isDownloading, setIsDownloading] = useState(false);

  const { generateMergedPdf } = useMergeRenewVisaFile(stepData.renewVisaDocumentId);

  const getUploadAvatarFile = async () => {
    const uploadAvatarFileData = await fetchRenewVisaUploadAvatarFileDocumentApi(stepData.renewVisaDocumentId, stepData.id).then((res) => res.data);

    const rs = await fetchSignedUrlApi(uploadAvatarFileData.avatarFilePath, "300x400");
    const url = rs.data.signedUrl;

    const fileInfoRs = await fetchFileInfoApi(uploadAvatarFileData.avatarFilePath);

    const extension = fileInfoRs.data.name.split(".").pop();

    const res = await axios.get(url, { responseType: "blob" });
    const avatarBlob = res.data;
    return { avatarBlob, extension };
  };

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const zip = new JSZip();

      const renewVisaDocument = await fetchRenewVisaDocumentInfoApi(stepData.renewVisaDocumentId).then((res) => res.data);
      if (!renewVisaDocument) return;

      const { firstName, lastName, middleName } = renewVisaDocument.employee;
      const fullName = getFullName({ firstName, lastName, middleName });

      //handle csv
      const rs = await fetchRenewVisaDocumentListToExportCsvApi({ ids: [stepData.renewVisaDocumentId] });
      const renewVisaList = rs.data
        ? rs.data.map((item) => ({ employeeApplication: item.employeeApplication, companyApplication: item.companyApplication }))
        : [];
      if (renewVisaList.length === 0) return;
      const csvDataArrayJson = convertRenewVisaToCsvData(renewVisaList);
      const csvData = json2csv(csvDataArrayJson, {
        prependHeader: true,
        delimiter: { field: "\t", eol: "\r\n" },
        expandArrayObjects: true,
        escapeHeaderNestedDots: false,
      });

      const csvWithBom = "\uFEFF" + csvData + "\r\n";
      const utf16Csv = iconv.encode(csvWithBom, "utf16le");
      const blob = new Blob([utf16Csv], { type: "text/csv;charset=utf-16le;" });
      zip.file(`${fullName}.csv`, blob);

      //handle pdf
      let mergedPdf: File | null | undefined = null;

      if (renewVisaDocument.mergedPdfFilePath) {
        mergedPdf = await generateFileFromFilePath(renewVisaDocument.mergedPdfFilePath);
      } else {
        mergedPdf = await generateMergedPdf();
        // Compress pdf file when the file size more then 10MB
        if (mergedPdf && mergedPdf.size > 9.8 * 1000 * 1000) {
          const compressRes = await compressPdfApi({ targetSizeMb: 9.8, file: mergedPdf }).then((res) => res.data);
          mergedPdf = await generateFileFromFilePath(compressRes.filePath);
          // Save the merged pdf file path to reuse later
          await updateRenewVisaDocumentMergedPdfFilePathApi(stepData.renewVisaDocumentId, { mergedPdfFilePath: compressRes.filePath });
        }
      }
      if (mergedPdf) {
        zip.file(`${fullName}の添付書類.pdf`, mergedPdf);
      }

      // handle avatar file
      const { avatarBlob, extension } = await getUploadAvatarFile();
      if (avatarBlob) {
        zip.file(`${fullName}の顔写真.${extension}`, avatarBlob);
      }

      const zipBlob = await zip.generateAsync({ type: "blob" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = fullName;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      showError(error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Button
      variant="primary"
      size="sm"
      disabled={readonly || stepData.status !== DOCUMENT_STATUS.COMPLETE}
      onClick={handleDownload}
      isLoading={isDownloading}
    >
      提出形式で保存
    </Button>
  );
};
