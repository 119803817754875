import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { finishSubmitDocumentStepApi } from "@/api/services/main/renew-visa";
import { Button } from "@/components/elements/Button";
import { Linkable } from "@/components/elements/Linkable";
import { StepCard } from "@/components/elements/StepCard";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { state } from "@/theme/colors";
import { showError } from "@/utils/error";
import { openInNewTab } from "@/utils/export";
import { toNumber } from "@/utils/number";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";

export type TRenewVisaOnlineSubmitApplicationStepProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
  readonly: boolean;
};

export const RenewVisaOnlineSubmitApplicationStep: FC<TRenewVisaOnlineSubmitApplicationStepProps> = ({ stepData, readonly }) => {
  const { stepOrder, stepName } = stepData.masterDocumentStep;
  const { id: documentStepId } = stepData;

  const queryClient = useQueryClient();

  const handleFinishOnlineSubmitApplication = async () => {
    if (!documentStepId) return;
    try {
      await finishSubmitDocumentStepApi({ documentStepId: toNumber(documentStepId) });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_DETAIL] });
      openInNewTab("https://www.ras-immi.moj.go.jp/WC01/WCAAS010/");
    } catch (error) {
      showError(error);
    }
  };
  return (
    <StepCard
      title={`ステップ${stepOrder}`}
      subTitle={stepName}
      buttons={
        <Button variant="primary" size="sm" disabled={readonly} onClick={() => handleFinishOnlineSubmitApplication()}>
          申請
        </Button>
      }
    >
      <Typography component="p" variant="body14" mb="8px">
        「申請」ボタンから在留申請オンラインシステムに移動します。
      </Typography>
      <Linkable href="https://support.iro-hana.com/787131b22028479c8fdd7eb2c05bbf73?pvs=4" target="_blank" width="170px">
        <Typography variant="link14Semi" color={state.link_1} sx={{ textDecoration: "underline" }}>
          申請フローの詳細はこちら
        </Typography>
      </Linkable>
    </StepCard>
  );
};
