import { setAxiosAuth } from "@/utils/api";
import { verifyToken } from "@/utils/token";
import { ClientRoutes } from "@/routes/config";
import Cookies from "js-cookie";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingScreen } from "@/components/elements/Loading/Loading";
import { ConfirmReloadModal } from "@/components/commons/ConfirmReloadModal";
import { Typography } from "@mui/material";
import { useDialog } from "@/hooks/useDialog";

export const withAuth: <CP extends {}>(WrappedComponent: FC<CP>) => FC<CP> = (WrappedComponent) => (props) => {
  const { pathname } = useLocation();
  const [isVerified, setIsVerified] = useState(false);
  const [userId, setUserId] = useState(Cookies.get("userId"));
  const navigate = useNavigate();
  const confirmReload = useDialog();

  useEffect(() => {
    // Every 2 seconds check user id, if user id has changed then redirect to login page
    setInterval(() => {
      if (userId && userId != Cookies.get("userId")) {
        location.href = "/login";
      }
    }, 2000);
  }, []);

  useEffect(() => {
    // const userId = Cookies.get("userId");
    // setUserId(userId);
    const token = Cookies.get("token");
    setAxiosAuth();
    const isAuthenticated = !!token && verifyToken(token);

    // verify admin routes
    if (!isAuthenticated && ClientRoutes.some((path) => pathname.startsWith(path))) {
      location.href = "/login";
    }

    setIsVerified(true);

    // check version build
    const checkVersion = async () => {
      try {
        const response = await fetch("/version.json", {
          headers: { "Cache-Control": "no-cache" },
        });
        const { version } = await response.json();
        const currentVersion = localStorage.getItem("app-version");

        // show modal and update version
        if (version && (!currentVersion || version !== parseInt(currentVersion))) {
          localStorage.setItem("app-version", version);
          const agree = await confirmReload.show();
          if (agree) {
            window.location.reload();
          }
        }
      } catch (error) {}
    };

    checkVersion();
  }, [pathname, navigate]);

  if (!isVerified) return <LoadingScreen />;

  return (
    <>
      <ConfirmReloadModal open={confirmReload.open} onOk={confirmReload.confirm}>
        <Typography>システムがアップデートされました。最新の状態にするため、ページをリロードしてください。</Typography>
      </ConfirmReloadModal>
      <WrappedComponent {...props} />
    </>
  );
};
